// import React, { useState, useEffect } from "react";
// import localData from "../../../localData";
// import {
//     Dropdown,
//     Select,
//     Button,
//     Calendar,
//     Loading,
//     TableSkeleton,
//     ChartSkeleton,
// } from "../../../components";
// import { v4 as uuidv4 } from "uuid";
// import { BarChart, LineChart } from "../../../components";
// import { useGlobalContext } from "../../../context";

// import { Tooltip } from "react-tooltip";
// import useFormatter from "../../../hooks/useFormatter";

// export default function CampaignsGraph() {
//     const {
//         requestedData: {
//             userProfileData,
//             campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
//         },
//         isLoading: { campaignDataIsLoading, userProfileIsLoading },
//         formatNumber,
//         getDateName,
//         activeCampaign,
//         isDarkModeEnabled,
//         campaignsState,
//     } = useGlobalContext();
//     const { campaignSelectionRange } = campaignsState;

//     const { formatCurrency, removeAlias, formatDateToMMDD } = useFormatter();

//     const { user, textPreloader } = localData.images;
//     const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

//     const [graphBtns, setGraphBtns] = useState({
//         impressions: {
//             isActive: true,
//             propertyName: "impressions",
//             displayName: "impressions",
//             color: "purple",
//         },
//         amount_spent: {
//             isActive: true,
//             propertyName: "amount_spent",
//             displayName: "Earnings",
//             color: "rgba(35, 149, 110,1)",
//         },

//         clicks: {
//             isActive: false,
//             propertyName: "clicks",
//             displayName: "clicks",
//             color: "rgba(21, 161, 197,1)",
//         },
//         CPM: {
//             isActive: false,
//             propertyName: "CPM",
//             displayName: "CPM",
//             color: "rgba(6, 23, 38,1)",
//         },
//         CPC: {
//             isActive: false,
//             propertyName: "CPC",
//             displayName: "CPC",
//             color: "rgba(249, 57, 57,1)",
//         },
//     });

//     const handleActiveChartBtn = (e) => {
//         const name = e.currentTarget.dataset.name;
//         setGraphBtns({
//             ...graphBtns,
//             [name]: {
//                 ...graphBtns[name],
//                 isActive: !graphBtns[name].isActive,
//             },
//         });
//     };

//     const [activeGraphCampaign, setActiveGraphCampaign] = useState(null);
//     const [filteredGraphData, setFilteredGraphData] = useState([]);

//     const formatDate = (date) => {
//         const day = String(date.getDate()).padStart(2, "0");
//         const month = String(date.getMonth() + 1).padStart(2, "0");
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };

//     const filterChartData = () => {
//         if (!currentOrganizationCampaigns.length) {
//             setFilteredGraphData([]);
//             return;
//         }
//         if (!activeGraphCampaign) return;

//         const { progressionData } = activeGraphCampaign;
//         if (!progressionData) return;
//         const { startDate, endDate } = campaignSelectionRange;
//         const array = [];

//         for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
//             const dateString = formatDate(date);
//             const dataForDate = progressionData[dateString];

//             if (dataForDate) {
//                 const obj = {};
//                 obj.name = getDateName({ date: dateString });

//                 if (graphBtns.impressions.isActive) obj.impressions = dataForDate.impressions || 0;
//                 if (graphBtns.amount_spent.isActive) obj.amount_spent = dataForDate.amount_spent || 0;
//                 if (graphBtns.clicks.isActive) obj.clicks = dataForDate.clicks || 0;
//                 if (graphBtns.CPM.isActive) obj.CPM = dataForDate.cpm || 0;
//                 if (graphBtns.CPC.isActive) obj.CPC = dataForDate.cpc || 0;

//                 array.push(obj);
//             } else {
//                 // const obj = {
//                 //     name: getDateName({ date: dateString }),
//                 //     impressions: graphBtns.impressions.isActive ? 0 : undefined,
//                 //     amount_spent: graphBtns.amount_spent.isActive ? 0 : undefined,
//                 //     clicks: graphBtns.clicks.isActive ? 0 : undefined,
//                 //     CPM: graphBtns.CPM.isActive ? 0 : undefined,
//                 //     CPC: graphBtns.CPC.isActive ? 0 : undefined,
//                 // };

//                 // array.push(obj);

//                 const obj = {};
//                 obj.name = formatDateToMMDD(date);
//                 obj.empty = 0;
//                 array.push(obj);
//             }
//         }
//         setFilteredGraphData(array);
//     };

//     useEffect(() => {
//         if (!currentOrganizationCampaigns.length) return;
//         if (!activeCampaign) return;
//         const tempActiveGraphCampaign = currentOrganizationCampaigns.find(
//             (item) => item.id === activeCampaign.id
//         );
//         setActiveGraphCampaign(tempActiveGraphCampaign);
//     }, [activeCampaign, currentOrganizationCampaigns]);

//     useEffect(() => {
//         filterChartData();
//     }, [activeGraphCampaign, graphBtns, currentOrganizationCampaigns]);

//     const [isLineChartActive, setIsLineChartActive] = useState(true);

//     return (
//         <div className="campaigns-graph shadow">
//             <div className="campaigns-graph-inner">
//                 <div className="graph">
//                     <div className="graph-header">
//                         <img src="https://firebasestorage.googleapis.com/v0/b/web3-marketing-hub.appspot.com/o/images%2Fuser.png?alt=media&token=55bb1159-4dc8-4a03-abf4-b2ba80ed52f9" alt="" />
//                         <p className="graph-text dark-mode-style">
//                             <span>Welcome to Blockchain-Ads</span>
//                             <strong className="dark-mode-style">
//                                 {userProfileIsLoading ? (
//                                     <img src={textPreloader} className="text-preloader" />
//                                 ) : (
//                                     userProfileData.contact_name
//                                 )}
//                             </strong>
//                         </p>
//                     </div>

//                     <div className="graph-body">
//                         <h2
//                             className="graph-title"
//                             data-tooltip-id="tooltip-graph-title"
//                             data-tooltip-content={
//                                 activeGraphCampaign && removeAlias(activeGraphCampaign.name)
//                             }
//                         > <span className="prefix">Website:</span>
//                             {campaignDataIsLoading
//                                 ? ""
//                                 : activeGraphCampaign &&
//                                    removeAlias(activeGraphCampaign.name)}
//                             <Tooltip id="tooltip-graph-title" className="custom-tooltip" />
//                         </h2>
//                         <div className="division">
//                             <div className="division-icon btn-icon">{eye}</div>
//                             <div className="wrapper">
//                                 <h6 className="division-title">Estimated Earnings</h6>
//                                 <div
//                                     className={` division-amount ${
//                                         isDarkModeEnabled ? "dark-mode-style" : ""
//                                     }`}
//                                 >
//                                     {campaignDataIsLoading ? (
//                                         <img src={textPreloader} className="text-preloader" />
//                                     ) : (
//                                         // formatCurrency(dashboardSummaryData?.totalAdSpend, 2)
//                                         formatCurrency(activeCampaign?.amount_spent, 2)
//                                     )}
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="division">
//                             <div className="division-icon btn-icon">{transactions}</div>
//                             <div className="wrapper">
//                                 <h6 className="division-title">Total Clicks</h6>
//                                 <div
//                                     className={` division-amount ${
//                                         isDarkModeEnabled ? "dark-mode-style" : ""
//                                     }`}
//                                 >
//                                     {campaignDataIsLoading ? (
//                                         <img src={textPreloader} className="text-preloader" />
//                                     ) : (
//                                         // formatNumber(dashboardSummaryData?.totalClicks?.toFixed(0) || "0")
//                                         formatNumber(activeCampaign?.clicks?.toFixed(0) || "0")
//                                     )}
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="division">
//                             <div className="division-icon btn-icon">{coins}</div>
//                             <div className="wrapper">
//                                 <h6 className="division-title">Total Impressions</h6>
//                                 <div
//                                     className={`division-amount ${
//                                         isDarkModeEnabled ? "dark-mode-style" : ""
//                                     } `}
//                                 >
//                                     {campaignDataIsLoading ? (
//                                         <img src={textPreloader} className="text-preloader" />
//                                     ) : (
//                                         formatNumber(
//                                             // dashboardSummaryData?.totalImpressions?.toFixed(0) || "0"
//                                             activeCampaign?.impressions.toFixed(0) || "0"
//                                         )
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="divider"></div>

//                 <div className={`chart-wrapper `}>
//                     {campaignDataIsLoading && (
//                         <div className="loader">
//                             <div className="message">Loading...</div>
//                             <img src={textPreloader} />
//                         </div>
//                     )}
//                     {/* <div className="row row-top">
//                         <Dropdown
//                             {...{
//                                 title: dateRange,
//                                 variant: "text",
//                                 color: "dark",
//                                 className: "calendar-dropdown",
//                                 isOpen: isCalendarOpen,
//                                 setIsOpen: setIsCalendarOpen,
//                                 size: "sm",
//                             }}
//                         >
//                             <Calendar
//                                 setIsOpen={setIsCalendarOpen}
//                                 {...{
//                                     dateRange,
//                                     setDateRange,
//                                     selectionRange,
//                                     setSelectionRange,
//                                     setDefaultSelectionRange,
//                                     callback: callback,
//                                 }}
//                             />
//                         </Dropdown>
//                     </div> */}
//                     <div className="row row-bottom">
//                         <div className="col col-left btn-group">
//                             <Button
//                                 variant="circle"
//                                 icon={lineChart}
//                                 size="sm"
//                                 color={isLineChartActive ? "dark" : "light"}
//                                 onClick={() => setIsLineChartActive(true)}
//                             />
//                             <Button
//                                 variant="circle"
//                                 icon={barChart}
//                                 size="sm"
//                                 color={isLineChartActive ? "light" : "dark"}
//                                 onClick={() => setIsLineChartActive(false)}
//                             />
//                         </div>
//                         <div className="col col-right btn-group">
//                             {Object.values(graphBtns).map((item, index) => {
//                                 return (
//                                     <div
//                                         key={index}
//                                         className={`chart-btn ${item.isActive ? "active" : ""}`}
//                                         data-name={item.propertyName}
//                                         onClick={handleActiveChartBtn}
//                                     >
//                                         <div
//                                             className="list-style"
//                                             style={{ backgroundColor: item.color }}
//                                         ></div>
//                                         {item.displayName}
//                                     </div>
//                                 );
//                             })}
//                         </div>
//                     </div>
//                     {/* {isLineChartActive ? ( */}
//                     <LineChart
//                         data={filteredGraphData}
//                         // defaultData={[
//                         //     {
//                         //         empty: 0,
//                         //         name: "",
//                         //     },
//                         //     // {
//                         //     //     empty: 0,
//                         //     //     name: "Campaign progression is empty",
//                         //     // },
//                         // ]}
//                         lines={Object.values(graphBtns)}
//                         isLoading={campaignDataIsLoading}
//                     />
//                     {/* ) : (
//                         <BarChart
//                             data={filteredGraphData}
//                             defaultData={[
//                                 {
//                                     empty: 0,
//                                     name: "Campaign progression is empty",
//                                 },
//                             ]}
//                             bars={Object.values(graphBtns)}
//                             isLoading={campaignDataIsLoading}
//                         />
//                     )} */}
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import {
    Dropdown,
    Select,
    Button,
    Calendar,
    Loading,
    TableSkeleton,
    ChartSkeleton,
} from "../../../components";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import { BarChart, LineChart } from "../../../components";
import { useGlobalContext } from "../../../context";

import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";

export default function CampaignsGraph() {
    const {
        requestedData: {
            userProfileData,
            campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
        },
        isLoading: { campaignDataIsLoading, userProfileIsLoading },
        formatNumber,
        getDateName,
        activeCampaign,
        markedCampaigns,
        isDarkModeEnabled,
        campaignsState,
        campaignData,
        statsData,
    } = useGlobalContext();
    const { campaignSelectionRange } = campaignsState;

    const { formatCurrency, removeAlias, formatDateToMMDD, addCommas } = useFormatter();

    const { user, textPreloader } = localData.images;
    const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

    const [graphBtns, setGraphBtns] = useState({
        impressions: {
            isActive: true,
            propertyName: "impressions",
            displayName: "impressions",
            normalizeName: "normalizeImpressions",
            color: "purple",
        },
        amount_spent: {
            isActive: true,
            propertyName: "amount_spent",
            displayName: "revenue",
            normalizeName: "normalizeAmount_spent",
            color: "rgba(35, 149, 110,1)",
        },

        clicks: {
            isActive: true,
            propertyName: "clicks",
            displayName: "clicks",
            normalizeName: "normalizeClicks",
            color: "rgba(21, 161, 197,1)",
        },
        CPM: {
            isActive: true,
            propertyName: "CPM",
            displayName: "CPM",
            normalizeName: "normalizeCPM",
            color: "rgba(6, 23, 38,1)",
        },
        CPC: {
            isActive: true,
            propertyName: "CPC",
            displayName: "CPC",
            normalizeName: "normalizeCPC",
            color: "rgba(249, 57, 57,1)",
        },
    });

    const handleActiveChartBtn = (e) => {
        const name = e.currentTarget.dataset.name;
        setGraphBtns({
            ...graphBtns,
            [name]: {
                // propertyName: graphBtns[name].propertyName,
                // displayName: graphBtns[name].displayName,
                ...graphBtns[name],
                isActive: !graphBtns[name].isActive,
            },
        });
    };

    // const [activeGraphCampaign, setActiveGraphCampaign] = useState(null);
    const defaultTotal = {
        impressions: 0,
        amount_spent: 0,
        clicks: 0,
        CPM: 0,
        CPC: 0,
    };
    const [total, setTotal] = useState(defaultTotal);
    const [filteredGraphData, setFilteredGraphData] = useState([]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatDateToYYYYDDMM = (date) => {
        let [month, day, year] = date.split("/");
        let formattedDate = `${year}-${day}-${month}`;
        return formattedDate;
    };

    const filterMultiGraphData = () => {
        // if (!statsData.length) {
        //     setFilteredGraphData([]);
        //     setTotal(defaultTotal);
        //     return;
        // }
        if (!markedCampaigns.length) {
            setFilteredGraphData([]);
            setTotal(defaultTotal);
            return;
        }

        // const selectionRange = JSON.parse(localStorage.getItem('selectionRange'))

        const { startDate, endDate } = campaignSelectionRange;
        const array = [];
        const object = {
            impressions: 0,
            amount_spent: 0,
            clicks: 0,
            CPM: 0,
            CPC: 0,
        };
        for (let date = new Date(startDate); date <= new Date(endDate); date.setDate(date.getDate() + 1)) {
            const dateString = formatDate(date);
            const aggregatedData = {
                name: getDateName({ date: dateString }),
                impressions: 0,
                amount_spent: 0,
                clicks: 0,
                CPM: 0,
                CPC: 0,

                normalizeImpressions: 0,
                normalizeAmount_spent: 0,
                normalizeClicks: 0,
                normalizeCPM: 0,
                normalizeCPC: 0,
            };

            let dataFound = false;

            const matchedItem = markedCampaigns[0].progressionData.find(
                (item) => formatDate(new Date(item.day)) == dateString
            );
            if (matchedItem) {
                dataFound = true;
                aggregatedData.name = getDateName({ date: formatDate(new Date(matchedItem.day)) });
                if (graphBtns.impressions.isActive) aggregatedData.impressions = matchedItem.impressions || 0;
                if (graphBtns.amount_spent.isActive) aggregatedData.amount_spent = matchedItem.revenue || 0;
                if (graphBtns.clicks.isActive) aggregatedData.clicks = matchedItem.clicks || 0;
                if (graphBtns.CPM.isActive) aggregatedData.CPM = matchedItem.cpm || 0;
                if (graphBtns.CPC.isActive) aggregatedData.CPC = matchedItem.cpc || 0;

                object.impressions += parseInt(matchedItem.impressions);
                object.amount_spent += parseInt(matchedItem.revenue);
                object.clicks += parseInt(matchedItem.clicks);
                object.CPM += parseInt(matchedItem.cpm);
                object.CPC += parseInt(matchedItem.cpc);
                const normalizer = () => {
                    aggregatedData.normalizeImpressions = Math.log10(aggregatedData.impressions + 1);
                    aggregatedData.normalizeAmount_spent = Math.log10(aggregatedData.amount_spent + 1);
                    aggregatedData.normalizeClicks = Math.log10(aggregatedData.clicks + 1);
                    aggregatedData.normalizeCPM = Math.log10(aggregatedData.CPM + 1);
                    aggregatedData.normalizeCPC = Math.log10(aggregatedData.CPC + 1);
                };
                normalizer();
            }

            if (dataFound) {
                array.push(aggregatedData);
            } else {
                // const emptyData = {
                //     name: formatDateToMMDD(new Date()),
                //     empty: 0,
                // };
                // array.push(emptyData);

                array.push(aggregatedData);
            }
        }
        setTotal(object);
        setFilteredGraphData(array);
    };

    useEffect(() => {
        filterMultiGraphData();
    }, [markedCampaigns, activeCampaign, graphBtns, currentOrganizationCampaigns, statsData]);

    const [isLineChartActive, setIsLineChartActive] = useState(true);

    return (
        <div className="campaigns-graph shadow">
            <div className="campaigns-graph-inner">
                <div className="graph" id="tour-step-1">
                    <div className="graph-header">
                        <img src={userProfileData.profile_image || user} alt="" />
                        <p className="graph-text dark-mode-style">
                            <span>Welcome to Blockchain-Ads</span>
                            <strong className="dark-mode-style">
                                {statsData.isLoading ? (
                                    <img src={textPreloader} className="text-preloader" />
                                ) : (
                                    userProfileData.userName
                                )}
                            </strong>
                        </p>
                    </div>

                    <div className="graph-body">
                        <h2
                            className="graph-title"
                            data-tooltip-id="tooltip-graph-title"
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                markedCampaigns.map((item) => <div>{item.name}</div>)
                            )}
                        >
                            <span className="prefix">Campaign(s):</span>
                            {statsData.isLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : markedCampaigns.length == 1 ? (
                                markedCampaigns[0].name
                            ) : markedCampaigns.length > 1 ? (
                                "Multiple Selected"
                            ) : (
                                "None Selected"
                            )}
                            <Tooltip id="tooltip-graph-title" className="custom-tooltip" />
                        </h2>
                        <div className="division">
                            <div className="division-icon btn-icon">{eye}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Revenue</h6>
                                <div
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {statsData.isLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        formatCurrency(total?.amount_spent, 2)
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{transactions}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Clicks</h6>
                                <div
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {statsData.isLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        addCommas(total?.clicks?.toFixed(2)) || "0"
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{coins}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Impressions</h6>
                                <div
                                    className={`division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    } `}
                                >
                                    {statsData.isLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        addCommas(
                                            // dashboardSummaryData?.totalImpressions?.toFixed(0) || "0"
                                            total?.impressions.toFixed(2)
                                        ) || "0"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider"></div>

                <div className={`chart-wrapper `}>
                    {statsData.isLoading && (
                        <div className="loader">
                            <div className="message">Loading...</div>
                            <img src={textPreloader} />
                        </div>
                    )}
                    {/* <div className="row row-top">
                        <Dropdown
                            {...{
                                title: dateRange,
                                variant: "text",
                                color: "dark",
                                className: "calendar-dropdown",
                                isOpen: isCalendarOpen,
                                setIsOpen: setIsCalendarOpen,
                                size: "sm",
                            }}
                        >
                            <Calendar
                                setIsOpen={setIsCalendarOpen}
                                {...{
                                    dateRange,
                                    setDateRange,
                                    selectionRange,
                                    setSelectionRange,
                                    setDefaultSelectionRange,
                                    callback: callback,
                                }}
                            />
                        </Dropdown>
                    </div> */}
                    <div className="row row-bottom">
                        <div className="col col-left btn-group">
                            <Button
                                variant="circle"
                                icon={lineChart}
                                size="sm"
                                color={isLineChartActive ? "dark" : "light"}
                                onClick={() => setIsLineChartActive(true)}
                            />
                            <Button
                                variant="circle"
                                icon={barChart}
                                size="sm"
                                color={isLineChartActive ? "light" : "dark"}
                                onClick={() => setIsLineChartActive(false)}
                            />
                        </div>
                        <div className="col col-right btn-group">
                            {Object.values(graphBtns).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`chart-btn ${item.isActive ? "active" : ""}`}
                                        data-name={item.propertyName}
                                        onClick={handleActiveChartBtn}
                                    >
                                        <div
                                            className="list-style"
                                            style={{ backgroundColor: item.color }}
                                        ></div>
                                        {item.displayName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* {isLineChartActive ? ( */}
                    <LineChart
                        isTooltipFormatted={markedCampaigns.length > 1 ? true : false}
                        data={filteredGraphData}
                        // defaultData={[
                        //     {
                        //         empty: 0,
                        //         name: "",
                        //     },
                        //     // {
                        //     //     empty: 0,
                        //     //     name: "Campaign progression is empty",
                        //     // },
                        // ]}
                        lines={Object.values(graphBtns)}
                        isLoading={statsData.isLoading}
                    />
                    {/* ) : (
                        <BarChart
                            data={filteredGraphData}
                            defaultData={[
                                {
                                    empty: 0,
                                    name: "Campaign progression is empty",
                                },
                            ]}
                            bars={Object.values(graphBtns)}
                            isLoading={campaignDataIsLoading}
                        />
                    )} */}
                </div>
            </div>
        </div>
    );
}
