// const express = require('express');
// const cors = require('cors');
// const app = express();

// app.use(cors());

// app.get('/api/data', (req, res) => {
//   res.json({ message: 'Hello, world!' });
// });

// app.listen(5000, () => {
//   console.log('Server is running on http://localhost:5000');
// });

import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import localData from "./localData";
import { ToastContainer, toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { auth, googleProvider, facebookProvider } from "./config/firebase.js";
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from "firebase/auth";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import useFetch from "./hooks/useFetch.js";
import useFormatter from "./hooks/useFormatter.jsx";
import useCampaignsState from "./hooks/state-hook/useCampaignsState.jsx";
import useBlockchainsState from "./hooks/state-hook/useBlockchainsState.jsx";
import useReportsState from "./hooks/state-hook/useReportsState.jsx";
import useAnalyticsState from "./hooks/state-hook/useAnalyticsState.jsx";
import useMarketplaceState from "./hooks/state-hook/useMarketplaceState.jsx";
import useTrackingState from "./pages/tracking/useTrackingState.jsx";
import useSort from "./hooks/useSort.jsx";
import useUtil from "./hooks/useUtil.jsx";
import useConvertKit from "./hooks/useConvertKit.jsx";

export const Context = createContext();

const { avatar, user, coinTelegraph, bitcoin, beInCrypto } = localData.images;

export default function Provider({ children }) {
    const {
        getZones,
        getStats,
        getUserData,
        getPublishersData,
        getAdvertiser,
        completeSignUp,
        resetAccountPassword,
        loadProfileData,
        loadUserProfile,
        loadBlockchainData,
        sumTransactionQuantities,
        updateTransaction,
        loadCampaignData,
        loadCampaignStatistics,
        // startOrStopCampaignZone,
        viewCampaignInfo,
        connectAnalyticsAccount,
        loadAnalyticsProfile,
        loadAnalyticsProject,
        disconnectAnalyticsAccount,
        switchOrganization,
        updateUserInfo,
        resetEmail,
        inviteUser,
        getAllCurrencies,
        playOrPauseCampaign,
        CreatePixelTag,
        CreateConversionPixel,
        getAdvertiserPixelStats,
    } = useFetch();

    // const { checkForBalanceOnLast7days } = useUtil();

    const { sendConvertKit } = useConvertKit();

    const { formatDateToYYYYMMDD, calculateTimeLeft } = useFormatter();

    const location = useLocation();
    const pathname = location.pathname;

    const navigate = useNavigate();

    const navbarRef = useRef(null);
    const marketplaceTheadRef = useRef(null);
    const marketplaceScrollItems = useRef(null);

    const [state, setState] = useState({
        fetchedData: null,
        localData,
    });
    const [animations, setAnimations] = useState({
        fade: {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.5 },
        },
    });
    const [inviteData, setInviteData] = useState({
        invited: false,
        inviteOrgID: "",
    });
    const [refCode, setRefCode] = useState("");

    // const [allCurrencies, setAllCurrencies] = useState([]);

    const [showSidebar, setShowSidebar] = useState(false);
    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
    const [isAdminTeamMember, setIsAdminTeamMember] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // useEffect(() => {
    //     if (isAdminTeamMember)
    //         checkForBalanceOnLast7days(
    //             requestedData.userProfileData.allOrganization
    //         );
    // }, [isAdminTeamMember]);

    useEffect(() => {
        initRequests();
        const isDarkModeEnabled = JSON.parse(localStorage.getItem("isDarkModeEnabled"));
        setIsDarkModeEnabled(isDarkModeEnabled);
        document.body.classList[isDarkModeEnabled ? "add" : "remove"]("dark-mode");
    }, []);

    const [placeholders, setPlaceholders] = useState({
        editProfile: {
            image: null,
            modalImage: null,
            croppedImage: null,
        },
    });

    const successAlert = (message = "success") => {
        toast.success(message);
    };
    const errorAlert = (message = "error") => {
        toast.error(message);
    };
    const warningAlert = (message = "warning") => {
        toast.warning(message);
    };

    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=handleGoogleSignIn= request error");
        }
    };

    const [isGoogleSignUpLoading, setIsGoogleSignUpLoading] = useState(false);
    const handleGoogleSignUp = async () => {
        setIsGoogleSignUpLoading(true);
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });

            const response = await signInWithPopup(auth, provider);
            sendConvertKit({
                name: response.user.displayName || response.user.email.split("@")[0],
                email: response.user.email,
            });
            // if (pathname === "/sign-up") {
            let googleSignUpData = {
                email: response.user.email,
                uuid: response.user.uid,
                photoURL: response.user.photoURL,
                name: response.user.displayName,
                invited: inviteData.invited,
                inviteOrgID: inviteData.inviteOrgID,
                referralCode: refCode,
            };

            if (!refCode) delete googleSignUpData.referralCode;

            const data = await completeSignUp(() => {}, googleSignUpData);
            successAlert("Successfully authenticated with Google account");
            getLoadUserProfile();

            // }
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            if (err?.response?.data?.res_data?.redirect) {
                handleSignOut();
            }

            console.error(err, "=handleGoogleSignUp= request error");
        }
        setIsGoogleSignUpLoading(false);
    };

    const handleFacebookSignIn = async () => {
        try {
            let response = await signInWithPopup(auth, facebookProvider);

            sendConvertKit({
                name: response.user.displayName || response.user.email.split("@")[0],
                email: response.user.email,
            });

            if (pathname === "/sign-up") {
                const { email, uid, photoURL, displayName } = response.user;

                const facebookSignUpData = {
                    email,
                    uuid: uid,
                    photoURL,
                    name: displayName,
                    invited: inviteData.invited,
                    inviteOrgID: inviteData.inviteOrgID,
                    referralCode: refCode,
                };

                if (!refCode) delete facebookSignUpData.referralCode;

                const data = await completeSignUp(() => {}, facebookSignUpData);
            }
        } catch (error) {
            console.error("Facebook Sign-In Error:", error.message);
        }
    };

    const [showCampaingWizardDrawer, setShowCampaignWizardDrawer] = useState("none");
    const [showProfileDrawer, setShowProfileDrawer] = useState("none");
    const [showDepositModal, setShowDepositModal] = useState("none");

    // REQUESTED STATES
    const DEFAULTREQUESTEDDATA = {
        userProfileData: {
            profile_image: user,
            userName: "Username",
            allOrganization: [],
            currentOrganization: {
                balance: 0,
            },
        },
        blockchainData: [],
        reportsData: [
            {
                date: "03/24/2024",
                accountName: "Account name 1",
                commissions: "Comissions 1",
                transactionID: "Transaction ID 1",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 2",
                commissions: "Comissions 2",
                transactionID: "Transaction ID 2",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 3",
                commissions: "Comissions 3",
                transactionID: "Transaction ID 3",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 4",
                commissions: "Comissions 4",
                transactionID: "Transaction ID 4",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 5",
                commissions: "Comissions 5",
                transactionID: "Transaction ID 5",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 6",
                commissions: "Comissions 6",
                transactionID: "Transaction ID 6",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 7",
                commissions: "Comissions 7",
                transactionID: "Transaction ID 7",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 8",
                commissions: "Comissions 8",
                transactionID: "Transaction ID 8",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 9",
                commissions: "Comissions 9",
                transactionID: "Transaction ID 9",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 10",
                commissions: "Comissions 10",
                transactionID: "Transaction ID 10",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 11",
                commissions: "Comissions 11",
                transactionID: "Transaction ID 11",
                isPaid: true,
            },
        ],
        campaignData: {
            currentOrganizationCampaigns: [],
            dashboardSummaryData: {
                totalAdAmountSpent: 0,
                totalClicks: 0,
                totalImpressions: 0,
            },
            dataSize: 0,
        },
        campaignStatistics: {
            tab: "",
            items: [],
        },
        campaignDataSummary: {},

        analyticsProfileData: {
            isFetched: false,
            accountSummaries: [],
        },
        analyticsProjectData: {
            mapData: {
                rows: [],
            },
            tableData: {
                rows: [],
            },
            transformedData: [],
        },
        allCurrenciesData: [],
        isAnalyticsConnected: false,
    };

    const [authUser, setAuthUser] = useState(null);

    const [requestedData, setRequestedData] = useState(DEFAULTREQUESTEDDATA);

    // LOADINGS STATES
    const [isLoading, setIsLoading] = useState({
        userProfileIsLoading: true,
        blockchainDataIsLoading: false,
        reportsDataIsLoading: false,
        campaignDataIsLoading: false,
        analyticsConnectIsLoading: false,
        analyticsDisconnectIsLoading: false,
        analyticsProfileIsLoading: false,
        analyticsProjectIsLoading: false,
        userInfoIsUpdating: false,
        emailIsUpdating: false,
        passwordIsUpdating: false,
        inviteUserIsLoading: false,
        campaignStatisticsLoading: false,
        updateTransactionIsLoading: false,
        // startOrStopCampaignZoneIsLoading: false
    });

    const [conversionOptions, setConversionOptions] = useState({
        pixelScriptTag: { value: "", isLoading: false },
        trackAnEventScriptTag: { value: "", isLoading: false },
        // advertiserPixelStats: { value: [], isLoading: false },
    });
    // CREATE PIXEL TAG   CreatePixelTag
    const CreatePixelTagRequest = async () => {
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        setConversionOptions((prev) => ({
            ...prev,
            pixelScriptTag: { ...prev.pixelScriptTag, isLoading: true },
        }));
        const user = localStorage.getItem("user"); // Assuming user info is stored in localStorage
        const parsedUser = JSON.parse(user);

        const organizationID = parsedUser.user_id;
        const tempRaw = {
            // advertiserId: auth.currentUser.uid,
            advertiserId: organizationID,
        };
        // try {
        //     const data = await CreatePixelTag(() => {}, tempRaw);
        //     console.log(data, "=CreatePixelTag= request");
        //     setConversionOptions((prev) => ({
        //         ...prev,
        //         pixelScriptTag: { value: data.scriptTag, isLoading: false },
        //     }));
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=CreatePixelTag= request error");
        //     setConversionOptions((prev) => ({
        //         ...prev,
        //         pixelScriptTag: { value: '', isLoading: false },
        //     }));
        // }
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    };
    const CreateConversionPixelRequest = async (tagName = "") => {
        setConversionOptions((prev) => ({
            ...prev,
            // trackAnEventScriptTag: { value: "", isLoading: false },
        }));
        const organizationID = 1;
        const tempRaw = {
            advertiserId: organizationID,
            tagName,
        };
        // try {
        //     const data = await CreateConversionPixel(() => {}, tempRaw);
        //     console.log(data, "=CreateConversionPixel= request");
        //     setConversionOptions((prev) => ({
        //         ...prev,
        //         trackAnEventScriptTag: { value: data.scriptTag, isLoading: false },
        //     }));
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=CreateConversionPixel= request error");
        //     setConversionOptions((prev) => ({
        //         ...prev,
        //         trackAnEventScriptTag: { value: "", isLoading: true },
        //     }));
        // }
    };

    // LOAD USER PROFILE DATA
    const getLoadUserProfile = async () => {
        const user = localStorage.getItem("user"); // Assuming user info is stored in localStorage
        if (!user) return;

        // Set loading states
        setIsLoading((prev) => ({ ...prev, userProfileIsLoading: true, campaignDataIsLoading: true }));

        // const temRaw = {
        //     uuid: user.user_id,
        // };
        const data = JSON.parse(user); // Replace with your API call
        // console.log(data.contact_name, "=loadUserProfile= request");
        setRequestedData((prev) => ({
            ...prev,
            userProfileData: data, // Update with actual profile data
        }));
        setIsLoading((prev) => ({ ...prev, userProfileIsLoading: false }));
        getLoadCampaignData(data.user_id, 1, 10);
    };

    // LOAD CAMPAIGN DATA

    const getLoadCampaignData = async (publisherid, page = 1, pageSize = 10) => {
        const user = localStorage.getItem("user");

        if (!user) return;
        setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: true }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };
        const storedPageSize = JSON.parse(localStorage.getItem("campaignsStates"))?.pageSize || pageSize;

        const tempRaw = {
            uuid: publisherid,
            publisherid,
            page,
            pageSize: storedPageSize,
            dateFrom: selectionRangeFormatted.dateFrom,
            dateTill: selectionRangeFormatted.dateTill,
        };
        // try {
        //     const data = await loadCampaignData(() => {}, tempRaw);
        //     console.log(data, "=loadCampaignData= request");

        //     setRequestedData((prev) => ({
        //         ...prev,
        //         campaignData: { isRequested: true, ...data.res_data },
        //     }));
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=loadCampaignData= request error");
        // }
        setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: false }));
    };

    const [timeRemaining, setTimeRemaining] = useState(0);

    const { handleOnClick, getSortIcon } = useSort({ requestedData, setRequestedData });

    const getLoadCampaignStatistics = async (campaignId, groupBy = "zone_id", activeTab = "") => {
        setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: true }));

        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            campaignId,
            organizationID,
            groupBy,
            dateFrom: selectionRangeFormatted.dateFrom,
            dateTill: selectionRangeFormatted.dateTill,
        };
        try {
            const data = await loadCampaignStatistics(() => {}, tempRaw);
            console.log(data, "=loadCampaignStatistics= request");

            // if (organizationID == "d2a4a5c0-05f3-4673-93bd-ec592e63346d") {
            //     data.res_data.items = []
            // }

            setRequestedData((prev) => ({
                ...prev,
                campaignStatistics: { ...data.res_data, tab: activeTab },
            }));
            // switch (groupBy) {
            //     case "zone_id":
            //         handleOnClick("zone_id", "desc");
            //         break;
            //     case "banner_id" || "country_id":
            //         handleOnClick("impressions", "desc");
            //         break;
            // }
            handleOnClick("impressions", "desc");
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=loadCampaignStatistics= request error");
            setRequestedData((prev) => ({
                ...prev,
                campaignStatistics: { tab: "", items: [] },
            }));
            navigate("/");
            warningAlert("Redirecting: Organization have been changed.");
        }
        setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: false }));
    };

    // LOAD BLOCKCHAIN DATA
    const getLoadBlockchainData = async (
        smartContractAddress,
        chainNetwork = "",
        page = 1,
        pageSize = 10000
    ) => {
        // "0x203C426C2cBDE770d8730FCBE64d16C8064F68FB"
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
        const tempRaw = {
            uuid: auth.currentUser.uid,
            chainNetwork,
            smartContractAddress,
            page,
            pageSize,
            order: "desc",
        };
        try {
            const data = await loadBlockchainData(() => {}, tempRaw);
            console.log(data, "=loadBlockchainData= request " + chainNetwork);

            setRequestedData((prev) => ({
                ...prev,
                blockchainData: data.res_data,
            }));
        } catch (err) {
            setRequestedData((prev) => ({
                ...prev,
                blockchainData: [],
            }));
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=loadBlockchainData= request error " + chainNetwork);
        }
        setIsLoading((prev) => ({
            ...prev,
            blockchainDataIsLoading: false,
        }));
    };

    // loadAnalyticsProfile
    const getLoadAnalyticsProfile = async (successCB = () => {}, errorCB = () => {}) => {
        if (!auth.currentUser) return;
        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
        };
        setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: true }));
        try {
            const data = await loadAnalyticsProfile(() => {}, temRaw);
            console.log(data, "=loadAnalyticsProfile= request");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { ...data.res_data, isFetched: true },
                isAnalyticsConnected: true,
            }));
            successCB(data.res_data.accountSummaries);

            // getLoadAnalyticsProject(data.res_data.accountSummaries[0].account.replace(/\D/g, ''));
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { isFetched: false, accountSummaries: [] },
                isAnalyticsConnected: false,
            }));
            errorCB();
            console.error(err, "=loadAnalyticsProfile= request error");
            getConnectAnalyticsAccount();
        }
        setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: false }));
    };

    // connectAnalyticsAccount
    const getConnectAnalyticsAccount = async () => {
        if (!auth.currentUser) return;
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get("code");
        // console.log("Value of code parameter:", authCode);
        navigate("/analytics");
        if (!authCode) return;
        setIsLoading((prev) => ({ ...prev, analyticsConnectIsLoading: true }));

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const tempRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
            authCode,
        };
        try {
            const data = await connectAnalyticsAccount(() => {}, tempRaw);
            console.log(data, "=connectAnalyticsAccount= request");
            setRequestedData((prev) => ({ ...prev, isAnalyticsConnected: true }));
            successAlert("Analytics connected, reloading the page...");
            // getLoadAnalyticsProfile();
            setTimeout(() => window.location.reload(), 2000);
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=connectAnalyticsAccount= request error");
        }
        setIsLoading((prev) => ({
            ...prev,
            analyticsConnectIsLoading: false,
        }));
    };

    // loadAnalyticsProject
    const getLoadAnalyticsProject = async (id) => {
        if (!auth.currentUser) return;

        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRange = JSON.parse(localStorage.getItem("analyticsSelectionRange")) || {
            startDate: formatDateToYYYYMMDD(startDate),
            endDate: formatDateToYYYYMMDD(endDate),
        };

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const temRaw = {
            uuid: auth.currentUser.uid,
            projectID: id,
            organizationID,
            ...selectionRange,
        };

        setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: true }));
        try {
            const data = await loadAnalyticsProject(() => {}, temRaw);
            console.log(data, "=loadAnalyticsProject= request");
            if (data.res_data.transformedData) {
                setRequestedData((prev) => ({
                    ...prev,
                    analyticsProjectData: data.res_data,
                }));
            } else {
                setRequestedData((prev) => ({
                    ...prev,
                    analyticsProjectData: {
                        mapData: {
                            rows: [],
                        },
                        tableData: {
                            rows: [],
                        },
                        transformedData: [],
                    },
                }));
            }
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            warningAlert(err?.response?.data?.res_msg || "No Data To Show!");
            console.error(err, "=loadAnalyticsProject= request error");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProjectData: {
                    mapData: {
                        rows: [],
                    },
                    tableData: {
                        rows: [],
                    },
                    transformedData: [],
                },
            }));
        }
        setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: false }));
    };

    // disconnectAnalyticsAccount
    const getDisconnectAnalyticsAccount = async () => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: true }));

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
        };
        try {
            const data = await disconnectAnalyticsAccount(() => {}, temRaw);
            console.log(data, "=disconnectAnalyticsAccount= request");
            // setRequestedData((prev) => ({ ...prev, analyticsProfileData: [] }));
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { accountSummaries: [] },
                isAnalyticsConnected: false,
            }));
            successAlert("Analytics disconnected");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=disconnectAnalyticsAccount= request error");
        }
        setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: false }));
    };

    // updateUserInfo
    const getUpdateUserInfo = async (info, successCallback = () => {}) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            info,
        };
        try {
            const data = await updateUserInfo(() => {}, temRaw);
            successAlert("personal information updated");
            successCallback();
            console.log(data, "=updateUserInfo= request");
            // if(auth?.currentUser) getLoadUserProfile();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=updateUserInfo= request error");
        }
        setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    };

    // resetEmail
    const getResetEmail = async (info) => {
        // if (!auth.currentUser) return;
        // setIsLoading((prev) => ({ ...prev, emailIsUpdating: true }));
        // const temRaw = {
        //     uuid: auth.currentUser.uid,
        //     info,
        // };
        // try {
        //     const data = await resetEmail(() => {}, temRaw);
        //     console.log(data, "=resetEmail= request");
        //     successAlert("email updated successfully");
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=resetEmail= request error");
        // }
        // setIsLoading((prev) => ({ ...prev, emailIsUpdating: false }));
    };

    // resetPassword
    const getResetPassword = async (info) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, passwordIsUpdating: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            info,
        };
        try {
            const data = await resetEmail(() => {}, temRaw);
            console.log(data, "=resetPassword= request");
            successAlert("email updated successfully");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=resetPassword= request error");
        }
        setIsLoading((prev) => ({ ...prev, passwordIsUpdating: false }));
    };

    // inviteUser
    const getInviteUser = async (invitedEmail, successCallback) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            senderName: requestedData.userProfileData.userName,
            inviteeEmail: invitedEmail,
        };
        try {
            const data = await inviteUser(() => {}, temRaw);
            console.log(data, "=inviteUser= request");
            successAlert("user invitation sent successfully");
            successCallback();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=inviteUser= request error");
        }
        setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: false }));
    };


    
    const formatDateToYYYYDDMM = (date) => {
        let [month, day, year] = date.split("/");
        let formattedDate = `${year}-${day}-${month}`;
        return formattedDate;
    };

    const [userData, setUserData] = useState({
        data: {},
        isLoading: false,
    });
    const [zonesData, setZonesData] = useState({
        data: [],
        isLoading: false,
    });
    const [statsData, setStatsData] = useState({
        data: [],
        isLoading: false,
    });
    const [balance, setBalance] = useState({
        data: 0,
        isLoading: false,
    });

    const [markedCampaigns, setMarkedCampaigns] = useState([]);

    const _getZones = async () => {
        setZonesData((prev) => ({ ...prev, isLoading: true }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };

        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const data = await getZones({
                id: userData.data.publisherId,
                start_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateFrom),
                end_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateTill),
            });

            console.log(data, "=getZones= request");
            setZonesData((prev) => ({ ...prev, data: data.zones.data, isLoading: false }));
        } catch (err) {
            console.error(err, "=getZones= request error");
        }
    };
    const _getStats = async () => {
        setStatsData((prev) => ({ ...prev, isLoading: true }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };

        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const data = await getStats({
                id: userData.data.publisherId,
                start_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateFrom),
                end_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateTill),
            });

            console.log(data, "=getStats= request");
            setStatsData((prev) => ({ ...prev,data: data.days.data, isLoading: false }));
            setMarkedCampaigns([{progressionData: data.days.data}])
        } catch (err) {
            console.error(err, "=getStats= request error");
        }
    };
    const _getAdvertiser = async () => {
        // setZonesData((prev) => ({ ...prev, isLoading: true }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };

        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const data = await getAdvertiser({
                id: userData.data.publisherId,
                // start_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateFrom),
                // end_date: formatDateToYYYYDDMM(selectionRangeFormatted.dateTill),
            });

            console.log(data, "=getAdvertiser= request");
            // setZonesData((prev) => ({ ...prev,data: data.zones.data, isLoading: false }));
            let tempBalance = 0;
            data.advertisers.data.forEach((item) => {
                tempBalance += parseFloat(item.revenue);
            });
            setBalance((prev) => ({ ...prev, data: tempBalance }));
        } catch (err) {
            console.error(err, "=getAdvertiser= request error");
        }
    };
    const _getUserData = async () => {
        setUserData((prev) => ({ ...prev, isLoading: true }));
        const publisher = JSON.parse(localStorage.getItem("user"));

        try {
            const data = await getUserData({
                id: publisher.user_id,
            });

            console.log(data, "=getUserData= request");
            setUserData((prev) => ({ ...prev, data: data, isLoading: false }));
            _getPublishersData();
        } catch (err) {
            console.error(err, "=getUserData= request error");
        }
    };
    const _getPublishersData = async () => {
        setUserData((prev) => ({ ...prev, isLoading: true }));
        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const data = await getPublishersData({
                // id: publisher.user_id,
            });

            console.log(data, "=getPublishersData= request");
            data.forEach((item) => {
                if (item.accountId == user.account_id) {
                    setUserData((prev) => ({
                        ...prev,
                        data: { ...prev.data, publisherId: item.publisherId },
                    }));
                }
                 else {
                    setUserData((prev) => ({
                        ...prev,
                        data: { ...prev.data, publisherId: 228 },
                    }));
                }
            });
        } catch (err) {
            console.error(err, "=getPublishersData= request error");
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if(!user) return
        _getUserData();
    }, []);

    useEffect(() => {
        // if (!Object.keys(userData.data).length) return;
        // _getPublishersData();

        if (!userData.data.publisherId) return;
        _getZones();
        _getStats();
        _getAdvertiser();
    }, [userData.data]);

    const initRequests = () => {
        // console.log(auth?.currentUser, " auth.currentUser");
        getLoadUserProfile();
    };

    const getShortText = (originalText) => {
        const charsToShow = 12; // You can adjust this value based on your preference
        const midPoint = originalText.length / 2;
        const startText = originalText.substring(0, midPoint - charsToShow);
        const endText = originalText.substring(midPoint + charsToShow);

        return startText + "..." + endText;
    };

    function formatDate(value = null) {
        const date = value !== null ? new Date(value) : new Date();
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const formatNumber = (value) => {
        const number = parseFloat(value.replace(/,/g, ""));

        if (!isNaN(number)) {
            return number.toLocaleString();
        }

        return value;
    };

    const getDelayedDate = (_date = "", delayTime) => {
        const date = new Date(_date);
        date.setDate(date.getDate() + delayTime);
        return date;
    };

    const getDateName = ({ date = "" }) => {
        var dateString = date;

        var parts = dateString.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        var dateObject = new Date(year, month - 1, day);
        var monthName = dateObject.toLocaleString("default", { month: "short" });
        return day + " " + monthName;
    };

    const calculateMinMax = (data) => {
        console.log("Minmax: ", data);
        let minMax = {
            impressions: { min: 0, max: -Infinity },
            amount_spent: { min: 0, max: -Infinity },
            clicks: { min: 0, max: -Infinity },
            cpm: { min: 0, max: -Infinity },
            cpc: { min: 0, max: -Infinity },
        };

        Object.values(data).forEach((item) => {
            Object.keys(minMax).forEach((key) => {
                if (item[key] !== undefined) {
                    if (item[key] < minMax[key].min) minMax[key].min = item[key];
                    if (item[key] > minMax[key].max) minMax[key].max = item[key];
                }
            });
        });

        return minMax;
    };

    const normalizeChartValue = (value, minMax = { min: 0, max: Infinity }) => {
        return ((value - minMax.min) / (minMax.max - minMax.min)) * 100;
    };

    const [selectedImages, setSelectedImages] = useState([]);
    const [creatives, setCreatives] = useState([]);
    const [singleCreativeInputs, setSingleCreativeInputs] = useState({});

    const deleteCreative = (id) => {
        let tempCreatives = [...creatives];
        tempCreatives = tempCreatives.filter((item) => item.id !== id);
        setCreatives(tempCreatives);
    };

    const defaultCampaign = {
        uuid: auth?.currentUser?.uid,
        // organizationID: requestedData.userProfileData.currentOrganization.id,
        campaignInfo: {
            campaignName: "",
            targetURL: "",
            campaignType: [],
            budget: "330",
            maxCPC: "0.50", //formatCurrency(state.maxCPC,3,"decimal")
            // startDate: formatDate(),
            // endDate: formatDate(getDelayedDate(30)),
            startDate: "",
            endDate: "",
            timezone: 3,
            smartContractAddress: "",
            chainTargeting: [],
            category: [],
            web3Audience: [],
            walletTargeting: [],
            deviceType: [],
            operativeSystem: [],
            browser: [],
            countriesTargeting: [],
            paymentunit: [],
        },
        creatives: [],
    };

    const [campaignState, setCampaignState] = useState(defaultCampaign);

    // useEffect(() => {
    //     console.log(campaignState, " campaignState");
    // }, [campaignState]);

    const [campaignWizardStatus, setCampaignWizardStatus] = useState("create"); //edit

    // const [campaigns, setCampaigns] = useState(null);
    const [activeCampaign, setActiveCampaign] = useState(null);
    const [activeInfoId, setActiveInfoId] = useState(null);

    useEffect(() => {
        setActiveCampaign(requestedData.campaignData.currentOrganizationCampaigns[0]);
        // setCampaigns(requestedData.campaignData.currentOrganizationCampaigns);
    }, [requestedData.campaignData.currentOrganizationCampaigns]);

    const campaignsState = useCampaignsState({ requestedData, getLoadCampaignData, _getZones,_getStats });
    const blockchainsState = useBlockchainsState({
        requestedData,
        setRequestedData,
        setIsLoading,
        activeCampaign,
        getLoadBlockchainData,
    });
    const reportsState = useReportsState();
    const analyticsState = useAnalyticsState({ requestedData });
    const marketplaceState = useMarketplaceState();
    const trackingState = useTrackingState();

    const handleSignOut = async (message = "You have successfully signed out!") => {
        const { resetCampaignsState } = campaignsState;
        const { resetReportsPagination } = reportsState;
        try {
            await auth.signOut();
            localStorage.clear();
            setRequestedData(DEFAULTREQUESTEDDATA);
            resetCampaignsState();
            resetReportsPagination();
            localStorage.removeItem("user");
            navigate("/sign-in");
            successAlert(message);
        } catch (error) {
            errorAlert("Error:", error.message);
            console.error("Error:", error.message);
        }
    };

    async function refreshAccessToken() {
        const refreshToken = JSON.parse(localStorage.getItem("user")).refresh_token;

        // Assuming you have a function to make the API call to refresh the token
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/token.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "refresh_token",
                client_id: "blockchain-ads",
                client_secret: "blockchain-adsapi",
                refresh_token: refreshToken,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to refresh access token");
        }

        const data = await response.json();
        return data;
    }

    async function updateTokens() {
        try {
            const data = await refreshAccessToken();
            const user = JSON.parse(localStorage.getItem("user"));

            // Update local storage with new tokens and expiry time
            localStorage.setItem(
                "user",
                JSON.stringify({
                    ...user,
                    access_token: data.access_token,
                    expires_at: data.expires_at,
                })
            );

            console.log("Access token updated successfully");
        } catch (error) {
            console.error("Error updating access token:", error);
        }
    }

    function isTokenExpired() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.expires_at) return true;

        return Date.now() / 1000 > parseInt(user.expires_at);
    }

    async function ensureTokenValid() {
        if (isTokenExpired()) {
            await updateTokens();
        }
    }


    return (
        <Context.Provider
            value={{
                zonesData,
                setZonesData,
                _getZones,
                _getUserData,
                _getStats,
                markedCampaigns,
                setMarkedCampaigns,
                state,
                balance,
                statsData,
                setBalance,
                ...state,
                setState,
                campaignsState,
                blockchainsState,
                reportsState,
                setRequestedData,
                isLoggedIn,
                setIsLoggedIn,
                animations,
                showSidebar,
                setShowSidebar,
                // isProfileImageSelected,
                // setIsProfileImageSelected,
                inviteData,
                setInviteData,
                setRefCode,
                // profileImagePlaceholder,
                // setProfileImagePlaceholder,
                // profileImage,
                // setProfleImage,
                placeholders,
                setPlaceholders,
                successAlert,
                errorAlert,
                warningAlert,
                handleGoogleSignIn,
                isGoogleSignUpLoading,
                handleGoogleSignUp,
                handleFacebookSignIn,
                // allCurrencies,
                // setAllCurrencies,
                showCampaingWizardDrawer,
                setShowCampaignWizardDrawer,
                showProfileDrawer,
                setShowProfileDrawer,
                showDepositModal,
                setShowDepositModal,
                campaignWizardStatus,
                setCampaignWizardStatus,
                isLoading,
                setIsLoading,
                requestedData,
                authUser,
                setAuthUser,
                getShortText,
                formatDate,
                getDelayedDate,
                formatNumber,
                getDateName,
                calculateMinMax,
                normalizeChartValue,
                navbarRef,
                marketplaceTheadRef,
                marketplaceScrollItems,
                // ANALYTICS
                analyticsState,

                // MAREKETPLACE
                marketplaceState,

                trackingState,

                // CREATIVES
                creatives,
                setCreatives,
                singleCreativeInputs,
                setSingleCreativeInputs,
                selectedImages,
                setSelectedImages,
                deleteCreative,
                //

                campaignState,
                setCampaignState,
                defaultCampaign,
                activeCampaign,
                setActiveCampaign,

                activeInfoId,
                setActiveInfoId,
                isDarkModeEnabled,
                isAdminTeamMember,
                setIsAdminTeamMember,
                setIsDarkModeEnabled,

                // REQUESTS
                initRequests,
                getConnectAnalyticsAccount,
                getDisconnectAnalyticsAccount,
                getLoadAnalyticsProfile,
                getLoadUserProfile,
                getLoadAnalyticsProject,
                // getReturnAnalyticsProject,
                getUpdateUserInfo,
                getResetEmail,
                getResetPassword,
                getInviteUser,
                getLoadCampaignData,
                // getLoadCampaignStatistics,
                getLoadCampaignStatistics,
                // getStartOrStopCampaignZone,
                getLoadBlockchainData,

                // campaigns,
                // setCampaigns,
                handleSignOut,
                ensureTokenValid,
                isTokenExpired,
                updateTokens,
                refreshAccessToken,
                timeRemaining,
                handleOnClick,
                getSortIcon,
                CreatePixelTagRequest,
                CreateConversionPixelRequest,
                // getAdvertiserPixelStatsRequest,
                conversionOptions,
                setConversionOptions,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export const useGlobalContext = () => useContext(Context);
