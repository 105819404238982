import React, { useState, useEffect, useRef } from "react";
import { Field, Switch, Button, MultiSelect, Select, Modal } from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import useFormatter from "../../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import TrackingHelp from "../../../pages/tracking/tracking-help/TrackingHelp";
import { postAPIwithAuth } from '../../../services/webService';
  
export default function CampaignEdit({ setActiveTab }) {
    const { 
        formatDate,
        getDelayedDate,
        // formatNumber,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
        updateTokens,
        successAlert
    } = useGlobalContext();
    const { formatNumber, formatCurrency, addCommas } = useFormatter();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const [zoneData, setZoneData] = useState([]);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const [formData, setFormData] = useState({});

    // const setDefaultOptions = ({ property, state, setState }) => {
    //     let tempState = [...state];
    //     tempState = tempState.map((item) => {
    //         const _property = campaignState.campaignInfo[property]
    //         if(!_property) return
    //         const isSelected = _property.includes(item.title);
    //         return { ...item, isSelected };
    //     });
    //     setState(tempState);
    // };

    // useEffect(() => {
    //     // let tempCampaignTypeOptions = [...campaignTypeOptions];
    //     // tempCampaignTypeOptions = tempCampaignTypeOptions.map((item) => {
    //     //     const isSelected = campaignState.campaignInfo.campaignType.includes(item.title);
    //     //     return { ...item, isSelected };
    //     // });
    //     // setCampaignTypeOptions(tempCampaignTypeOptions);
    //     setDefaultOptions({
    //         property: "chainTargeting",
    //         state: chainTargetingOptions,
    //         setState: setChainTargetingOptions,
    //     });
    // }, []);

    const [state, setState] = useState({
        campaignName: campaignState.campaignInfo.campaignName,
        targetURL: campaignState.campaignInfo.targetURL,
        // smartContractAddress: campaignState.campaignInfo.smartContractAddress,
        budget: campaignState.campaignInfo.budget,
        maxCPC: campaignState.campaignInfo.maxCPC,
        startDate: campaignState.campaignInfo.startDate,
        endDate: campaignState.campaignInfo.endDate,
        paymentunit: campaignState.campaignInfo.paymentunit || "",
        campaignType: [],
    });
    //console.log(state.paymentunit);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [editwebid, setwebid] = useState(true);
    const drawerclose = document.querySelector('.drawer-backdrop');
    const refresh = document.querySelector('.refresh');
    const storedValue = localStorage.getItem('webid');
    const [webdata, setwebdata] = useState([]);
    //setwebid(storedValue);
    const fetchCampaignData = async () => {
        try {
            const responseData = await postAPIwithAuth(`publisher/publishersget.php`, { affiliateid: storedValue });
            
            console.log(responseData.result)
            setZoneData(responseData.result);

            setState((prevState) => ({
                ...prevState,
                ['campaignName']: responseData.result.name,
                ['targetURL']: responseData.result.website,
            }));
          
        } catch (error) {
            console.log(error)
            //LogoutService(navigate);
        }
    };
    const newFormData = {
        affiliateid: '',        
        name: '',
        website: '',
        category: ''
    };
    const createZone = async (state) => {
       
       // let newFormData = [];
       
        newFormData.affiliateid = storedValue;
        // const splitParts = formData.zonesize.split('X').map(part => part.trim());
         newFormData.name = state.campaignName;
         newFormData.website = state.targetURL;
         newFormData.category = state.category;
         
        try {
            const responseData = await postAPIwithAuth(`publisher/editwebsite.php`,newFormData);
            successAlert(`Website updated successfully!`);
            drawerclose.click();
            refresh.click();
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchCampaignData();

        
    }, []);
    const { budget } = localData.svgs;

    function checkIsValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // const handleMultiSelectChange = (items, name) => {
    //     let selectedItem = items?.filter((item) => item.isSelected);
    //     selectedItem = selectedItem?.map((item) => item.title);

    //     setState((state) => ({ ...state, [name]: selectedItem }));
    // };
    const handleSelectChange = (updatedItems, name) => {
        localStorage.setItem("paymentunitselected",updatedItems.title)
          
        if (!Array.isArray(updatedItems)) {
            console.error("items is not an array:", updatedItems.title);
            return;
        }
             // console.log(updatedItems);  
        let selectedItem = updatedItems.find((item) => item.isSelected);
        

        if (!selectedItem) {
            console.error("No item is selected in items:", updatedItems);
            return;
        }
        
        setState((state) => ({ ...state, [name]: selectedItem.title }));
    };



    const [errors, setErrors] = useState({
        campaignNameError: false,
        targetURLError: false,
        // smartContractAddressError: false,
        budgetError: false,
        startDateError: false,
        endDateError: false,
    });

    useEffect(() => {
        // console.log(Date.now(), " - date now");
        // console.log(new Date(startDateRef.current.value).getTime(), " start date");

        // setErrors({
        //     ...errors,
        //     campaignNameError: state.campaignName === "",
        //     targetURLError: state.targetURL === "" || !checkIsValidUrl(state.targetURL),
        //     // smartContractAddressError:
        //     //     state.smartContractAddress !== "" && !checkIsBlockchainAddress(state.smartContractAddress),
        //     budgetError: state.budget < 330,
        //     // startDateError: state.startDate === "",
        //     // campaignTypeError: !state.campaignType.length,
        // });
    }, [state]);

    useEffect(() => {
        // console.log(Date.now(), " - date now");
        // console.log(new Date(startDateRef.current.value).getTime(), " start date");

        // setErrors({
        //     ...errors,
        //     campaignNameError: state.campaignName === "",
        //     targetURLError: state.targetURL === "" || !checkIsValidUrl(state.targetURL),
        //     // smartContractAddressError:
        //     //     state.smartContractAddress !== "" && !checkIsBlockchainAddress(state.smartContractAddress),
        //     budgetError: state.budget < 330,
        //     // startDateError: state.startDate === "",
        //     // campaignTypeError: !state.campaignType.length,
        // });
    }, [state]);

    // useEffect(() => {
    //     console.log(errors, " errors");
    // }, [errors]);

    useEffect(() => {
        const haveError =
            !errors.campaignNameError &&
            !errors.targetURLError &&
            // !errors.smartContractAddressError &&
            !errors.budgetError;
        setIsButtonDisabled(!haveError);
    }, [errors]);

    function checkIsBlockchainAddress(text) {
        var pattern = /^0x[a-fA-F0-9]+$/;
        return pattern.test(text);
    }

    const [campaignTypeOptions, setCampaignTypeOptions] = useState([
        { title: "Awareness (max reach)", isSelected: false, id: uuidv4() },
        { title: "Engagement (website visit/interaction)", isSelected: false, id: uuidv4() },
        { title: "Conversion (website download/transactions/sales)", isSelected: false, id: uuidv4() },
    ]);

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };
    const handleSubmit = (e) => {
      //  console.log(state);
        createZone(state)
    };
    const setDefaultOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    useEffect(() => {
        setDefaultOptions({
            property: "campaignType",
            state: campaignTypeOptions,
            setState: setCampaignTypeOptions,
        });
        setDefaultOptions({ property: "category", state: categoryOptions, setState: setCategoryOptions });
    }, []);
    const [data, setData] = useState(undefined);


    const paymentUnitOptions = [
        { value: "cpm", title: "CPM", isSelected: false,id: uuidv4() },
        { value: "cpc", title: "CPC", isSelected: false,id: uuidv4() },
        { value: "cpa", title: "CPA", isSelected: false,id: uuidv4() }
    ];

    const options = [
        "CPM",
        "CPC",
        "CPA"        
    ];
    const onOptionChangeHandler = (event) => {
        setData(event.target.value);
        console.log(
            "User Selected Value - ",
            event.target.value
        );
    };
    const handleAnotherButtonClick = () => {
        // Call the submit handler directly
        handleSubmit(new Event('submit'));
      };
    const [categoryOptions, setCategoryOptions] = useState([
        { title: "Decentralized Finance", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Blockchain Gaming", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Marketplace", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Infrastructure", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "NFT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "DEX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "CEX", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Other", isSelected: false, size: "", id: uuidv4() },
    ]);

    const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");
    const [items, setItems] = useState(paymentUnitOptions);


    return (
        <div className="campaign-information">
            <div className="wrapper tab-content-center">
                <div className="row row-top">
                    <div className="col col-left">
                    <form onSubmit={handleSubmit}>
                        <Field 
                            type="text"
                            label="Website Name *"
                            labelInfo="Choose a name for your campaign. Make sure to pick a unique name to better recognize your campaign later."
                            name="campaignName"
                            value={zoneData.name}
                            color="secondary"
                            placeholder="e.g. johnDoeCamp"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                        /> 

                        <Field
                            type="text"
                            label="Website URL*"
                            labelInfo="Select a Landing Page for your ads. We recommend using UTM Parameters to track the performance of your campaigns."
                            name="targetURL"
                            value={zoneData.website}
                            color="secondary"
                            placeholder="e.g. https://johndoe.com"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                            
                         
                        />

                        {/* <Field
                            type="text"
                            label="Smart Contract"
                            labelInfo="(Optional) Select the Smart Contract of your project, so аre able to track transactions."
                            color="secondary"
                            placeholder="e.g. 0x00000000000000"
                            required={true}
                            size="sm"
                            name="smartContractAddress"
                            value={state.smartContractAddress}
                            callback={handleOnChange}
                            errorMessage={
                                state.smartContractAddress !== "" &&
                                !checkIsBlockchainAddress(state.smartContractAddress)
                                    ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                                    : ""
                            }
                        /> */}

                        <MultiSelect
                            {...{
                                items: categoryOptions,
                                setItems: setCategoryOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Category",
                                labelInfo: "(Optional) Select your project’s vertical.",
                                name: "category",
                               callback: handleMultiSelectChange,
                            }}
                        />
 
                        {/* <Field
                            type="text"
                            label="Budget (USD/Day)*"
                            labelInfo="Choose how much you want to spend each day. Minimum is $330"
                            name="budget"
                            value={state.budget}
                            color="secondary"
                            placeholder="Budget" 
                            required={true}
                            size="sm"
                            startIcon={budget}
                            callback={handleOnChange}
                            errorMessage={errors.budgetError && "Monthly budget must be at least $330."}
                        /> */}
                         {/* <Select
                            label="Payment Unit*"
                            items={items} 
                            setItems={setItems}
                            placeholder="Choose which payment unit (CPM, CPC, CPA)"
                            className=""
                            variant="outlined"
                            color="secondary"
                            size="md"
                            isLoading={false}
                            callback={(updatedItems) => handleSelectChange(updatedItems,"paymentoption")}
                        /> */}
                        </form>
                    </div>
                </div>

                <div className="row row-bottom btn-group">
                <Button
                            className="submit-btn"
                            size="sm"
                            name={campaignWizardStatus === "create" ? "Save & Continue" : "Edit & Continue"}
                            variant="contained"
                            color={campaignWizardStatus === "create" ? "success" : "warning"}
                            onClick={handleAnotherButtonClick}
                            
                        />
                </div>
            </div>
        </div>
    );
}

const InstallPixelModal = ({ setIsOpen, setActiveTab }) => {
    const { conversionOptions, campaignsState } = useGlobalContext();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                {/* need to confirm
                <br />
                <br /> */}
                <TrackingHelp />
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        className="next-step-btn"
                        name="Continue"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={(e) => {
                            closeModal();
                            setTimeout(() => {
                                // setActiveTab(1);
                                setActiveTab((prevNumber) => prevNumber + 1);
                                setCampaignTabs({
                                    ...campaignTabs,
                                    clickable: [...campaignTabs.clickable, 1],
                                    marked: [...campaignTabs.marked, 1],
                                });
                            }, 500);
                        }}
                    />
                </div>
            </div>
        </>
    );
};
