import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import localData from "../../localData.js";
import { useGlobalContext } from "../../context.js";
import { Button, Select, InfoPopup, Modal, Accounts } from "../";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../../config/firebase.js";
import useFetch from "../../hooks/useFetch.js";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";

const adNetwork = ["/", "/campaigns", "/analytics"];
const marketplace = ["/additional-products/chat", "/additional-products/pr"];
const supports = ["/support"];

export default function Sidebar() {
    const {
        bar,
        campaigns,
        strategist,
        arrowRight,
        support,
        ecoProduct,
        cube,
        trendUp,
        arrowUpRightDots,
        arrowsLeftRight,
        info,
        moneyBillTransfer,
        angleRight,
        arrowDown,
    } = localData.svgs;
    const { logo, logoSm, databaseIcon } = localData.images;
    const location = useLocation();
    const {
        showSidebar,
        setShowSidebar,
        errorAlert,
        requestedData: { userProfileData },
        getLoadUserProfile,
        requestedData,
    } = useGlobalContext();

    var parsedUser;

    const { switchOrganization } = useFetch();
    const [isLoading, setIsLoading] = useState();

    // switchOrganization
    const getSwitchOrganization = async (id) => {
        if (!auth.currentUser) return;
        // setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
        setIsLoading(true);
        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID: id,
        };
        try {
            const data = await switchOrganization(() => {}, temRaw);
            console.log(data, "=switchOrganization= request");
            getLoadUserProfile();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=switchOrganization= request error");
        }
        // filterAllOrganization();
        setIsLoading(false);
    };

    const [items, setItems] = useState([]);
    const [state, setState] = useState();

    const handleSelectChange = (updatedItems, name) => {
        const itemsArray = Array.isArray(updatedItems) ? updatedItems : [updatedItems];

        const selectedItem = itemsArray.find((item) => item.isActive === true);

        console.log(selectedItem);

        if (!selectedItem) {
            console.error("No item is selected in items:", itemsArray);
            return;
        }

        setState((state) => ({ ...state, [name]: selectedItem.title }));
    };

    const filterAllOrganization = () => {
        // setIsLoading(true);
        // let array = userProfileData.allOrganization.map((item, index) => {
        //     return {
        //         title: item.name || `---Not Exist---`,
        //         isSelected: item.id === userProfileData.currentOrganization.id,
        //         id: item.id,
        //         color:
        //             item.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
        //                 ? "purple"
        //                 : item.adminID === userProfileData.userId
        //                 ? "success"
        //                 : "",
        //     };
        // });
        // array.sort((a, b) => {
        //     const titleA = a.title.toLowerCase();
        //     const titleB = b.title.toLowerCase();
        //     if (titleA < titleB) return -1;
        //     if (titleA > titleB) return 1;
        //     return 0;
        // });
        //setItems(array);
        // setTimeout(() => {
        //     setIsLoading(false);
        // },1000);
    };

    useEffect(() => {
        // if (!userProfileData.allOrganization.length) return;
        filterAllOrganization();
    }, [userProfileData]);

    // useEffect(() => {
    //     items.pop();
    //     const loggeduser = localStorage.getItem("user");
    //     parsedUser = JSON.parse(loggeduser);
    //     items.push({"title":parsedUser.contact_name,"id":uuidv4(),isSelected:true});
    //     console.log(items);

    //     if (!items.length) return;

    //     const user = items.find((item) => item.isSelected);
    //     if (!user) return;
    //     //const id = user.id;
    //     //if (id == userProfileData.currentOrganization.id) return;
    //     //getSwitchOrganization(id);
    // }, [items]);

    useEffect(() => {
        let tempItems = [...items];
        const user = JSON.parse(localStorage.getItem("user"));
        let singleItem = { title: user.contact_name, id: uuidv4(), isActive: true };
        tempItems = [...tempItems, singleItem];
        if (!tempItems.length) return;

        const selectedUser = tempItems.find((item) => item.isActive);
        if (!selectedUser) return;
        setItems(tempItems);
    }, []);

    const [displayAccountsModal, setDisplayAccountsModal] = useState("none");

    const [isPRMenuOpen, setIsPRMenuOpen] = useState(false);

    return (
        <>
            <aside
                className={`sidebar ${showSidebar ? "show" : ""} ${
                    marketplace.includes(location.pathname) ? "sidebar-marketplace" : ""
                }
                ${adNetwork.includes(location.pathname) ? "sidebar-ad-network" : ""}
                ${supports.includes(location.pathname) ? "sidebar-support" : ""}
                `}
                id="sidebar"
            >
                <div className="sidebar-inner">
                    <div className="sidebar-header">
                        <img className="sidebar-logo" src={logo} />
                        <img className="sidebar-logo sidebar-logo-sm" src={logoSm} />
                    </div>
                    <div className="sidebar-body">
                        <div className={`list-wrapper ad-network-wrapper  `}>
                            <h4 className={`sidebar-text   `}>AD Network</h4>
                            <ul className="sidebar-list">
                                <Link
                                    to="/statistics"
                                    className={`sidebar-link ${
                                        location.pathname == "/statistics" || location.pathname == "/"
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        window.innerWidth < 992 && setShowSidebar(false);
                                    }}
                                >
                                    {campaigns}
                                    <span> Earnings & Stats </span>
                                    <div
                                        data-tooltip-id="sidebar-campaigns-tooltip"
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            "View your websites."
                                        )}
                                        className="info-popup"
                                    >
                                        {info}
                                    </div>
                                    <Tooltip
                                        id="sidebar-campaigns-tooltip"
                                        className="custom-tooltip sidebar-tooltip"
                                    />
                                </Link>

                                <Link
                                    to="/my-websites"
                                    className={`sidebar-link ${
                                        location.pathname == "/my-websites" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                        window.innerWidth < 992 && setShowSidebar(false);
                                    }}
                                >
                                    {moneyBillTransfer}
                                    <span> My Websites </span>
                                    <div
                                        data-tooltip-id="sidebar-campaigns-tooltip"
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            "Manage your websites and zones."
                                        )}
                                        className="info-popup"
                                    >
                                        {info}
                                    </div>
                                    <Tooltip
                                        id="sidebar-campaigns-tooltip"
                                        className="custom-tooltip sidebar-tooltip"
                                    />
                                </Link>
                            </ul>
                        </div>

                        <div className={`list-wrapper supports-wrapper   `} style={{ padding: "5px 0" }}>
                            <ul className="sidebar-list" style={{ marginTop: 0 }}>
                                <Link
                                    to="/support"
                                    className={`sidebar-link small-icon ${
                                        location.pathname == "/support" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                        window.innerWidth < 992 && setShowSidebar(false);
                                    }}
                                >
                                    {support}
                                    <span> Support </span>
                                    <div
                                        data-tooltip-id="sidebar-support-tooltip"
                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                            "Connect with the Blockchain-Ads team."
                                        )}
                                        className="info-popup"
                                    >
                                        {info}
                                    </div>
                                    <Tooltip
                                        id="sidebar-support-tooltip"
                                        className="custom-tooltip sidebar-tooltip"
                                    />
                                </Link>
                            </ul>
                        </div>
                    </div>

                    <div className="sidebar-footer">
                        <h4 className="title">
                            <span className="title-text">account</span>
                            <div
                                data-tooltip-id="sidebar-accounts-tooltip"
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    "Select your account."
                                )}
                                className="info-popup"
                            >
                                {info}
                            </div>
                            <Tooltip
                                id="sidebar-accounts-tooltip"
                                className="custom-tooltip sidebar-tooltip"
                            />
                        </h4>
                        <Select
                            items={items}
                            setItems={setItems}
                            placeholder="Select"
                            variant="outlined"
                            color="light"
                            className="organization-select"
                            callback={(updatedItems) => handleSelectChange(updatedItems, "zone")}
                        />
                        {/* {userProfileData.account_type ===
                            "Administrator" && (
                            <Modal
                                Toggler={() => (
                                    <Button
                                        name="Accounts"
                                        variant="contained"
                                        color="purple"
                                        className="accounts-btn"
                                        endImageIcon={databaseIcon}
                                        onClick={() => setDisplayAccountsModal("block")}
                                    />
                                )}
                                Child={Accounts} 
                                // modalCloseCallback={modalCloseCallback}
                                display={displayAccountsModal}
                                setDisplay={setDisplayAccountsModal}
                                className="modal-dialog-centered modal-fullscreen modal-dialog-scrollable accounts"
                            />
                        )} */}
                        <div className="copyright">©2024 Blockchain-Ads Labs, LLC.</div>
                    </div>
                </div>
            </aside>

            <div
                className={`sidebar-backdrop ${showSidebar ? "show" : ""}`}
                onClick={() => setShowSidebar(false)}
            ></div>
        </>
    );
}
