import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../context";
import { useStateContext } from "../../stateContext";
import { Modal } from "../../components";

import {
    Navbar,
    Button,
    CampaignsTable,
    ActivityTable,
    Skeleton,
    Select,
    Drawer,
    CampaignsWizard,
    Tabs,
} from "../";
import localData from "../../localData";
import CampaignInformation from "./tabs/CampaignInformation";
import Targeting from "./tabs/Targeting";
import Summary from "./tabs/Summary";
import Zonecreation from "./tabs/Zonecreation";
import CampaignEdit from "./tabs/CampaignEdit";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop, 
} from "react-image-crop";
import useCrop from "../../hooks/useCrop";
import useUtil from "../../hooks/useUtil";

export default function CampaingsWizard() {
    const {
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        campaignWizardStatus,
        setCampaignWizardStatus,
        requestedData,
        isLoading,
        formatDate,
        campaignState,
        setCampaignState,
        defaultCampaign,
        setSelectedImages,
        setCreatives,
        campaignsState,
    } = useGlobalContext();

    const { campaignTabs, setCampaignTabs, displayCreativesModal, setDisplayCreativesModal } = campaignsState;
    const [showzoneWizardDrawer1, setShowzoneWizardDrawer1] = useState("none");
    const { balance } = "0";

    const { plus, arrowRightExtended, checkbox, alert } = localData.svgs;

    const tabs = [
        {
            title: "Website Information",
            startIcon: checkbox,
            content: <CampaignInformation />,
            id: "campain-information-btn",
            tabClickable: campaignTabs.clickable.includes(1),
            tabMarked: campaignTabs.marked.includes(1),
            tabDisabled: campaignTabs.disabled.includes(1),
        },        
        // {
        //     title: "Summary",
        //     startIcon: checkbox,
        //     content: <Summary />,
        //     id: "summary-btn",
        //     tabClickable: campaignTabs.clickable.includes(4),
        //     tabMarked: campaignTabs.marked.includes(4),
        //     tabDisabled: campaignTabs.disabled.includes(4),
        // },
        {
            title: "Zone Creation",
            startIcon: checkbox,
            content: <Zonecreation/>,
            id: "zonecreation-btn",
            tabClickable: campaignTabs.clickable.includes(2),
            tabMarked: campaignTabs.marked.includes(2),
            tabDisabled: campaignTabs.disabled.includes(2),
        },
        {
            title: "Invocation codes",
            startIcon: checkbox,
            content: <Targeting />,
            id: "targeting-btn", 
            tabClickable: campaignTabs.clickable.includes(2),
            tabMarked: campaignTabs.marked.includes(2),
            tabDisabled: campaignTabs.disabled.includes(2),
        },
    ];
    const tabs1 = [
        {
            title: "Website Information",
            startIcon: checkbox,
            content: <CampaignEdit/>,
            id: "campain-information-btn",
            tabClickable: campaignTabs.clickable.includes(1),
            tabMarked: campaignTabs.marked.includes(1),
            tabDisabled: campaignTabs.disabled.includes(1),
        }
    ];
    const modalCloseCallback = () => { 
        console.log("modal close callback");
        // setPlaceholders((prev) => ({
        //     ...prev,
        //     editProfile: {
        //         ...placeholders.editProfile,
        //         modalImage: null,
        //     },
        // }));
    };

    return (
        <>
            <Modal
                overlayClickDisabled={true}
                id="creatives-modal"
                // Toggler={EditAvatarToggler}
                Toggler={() => {}}
                Child={ModalChild} 
                modalCloseCallback={modalCloseCallback}
                display={displayCreativesModal}
                setDisplay={setDisplayCreativesModal}
                className="modal-dialog-centered"
            />
            <div className={`campaigns-wizard campaigns-wizard-${campaignWizardStatus}`}     style={{ marginLeft: "auto" }}>
                <Drawer
                    display={showCampaingWizardDrawer}
                    setDisplay={setShowCampaignWizardDrawer}
                    // tooltipContent={`${
                    //     balance.toFixed(0) > 0
                    //         ? "Launch a new ad campaign."
                    //         : "“Top up your account balance before launching a campaign”."
                    // }`}
                    // disabled={balance.toFixed(0) > 0 ? false : true}
                    toggler={
                        <Button
                            name="Add new website"
                            variant="contained" 
                            startIcon={plus}
                            color="success"
                            
                            // disabled={ balance.toFixed(0) > 0 ? false : true}
                        />
                    }
                    className="drawer-right drawer-fullscreen"
                    callback={() => {
                        setCampaignWizardStatus("create");
                        setCampaignState(defaultCampaign);
                        setSelectedImages([]);
                        setCreatives([]);
                        setCampaignTabs({
                            clickable: [],
                            marked: [],
                            disabled: [],
                        });
                    }} 
                >
                    {campaignWizardStatus === "create" && balance <= 0 && (
                        <marquee direction="left">
                            <div className="alert alert-warning">
                                {alert} “Top up your account balance before launching a campaign”.
                            </div>
                        </marquee>
                    )}
                    <h2 className="campaigns-wizard-title display-2">
                        {campaignWizardStatus == "create" ? "add new" : "edit"} Website
                    </h2>
                    <Tabs
                        tabsProps={{
                            campaignWizardStatus,
                            setCampaignWizardStatus,
                        }}
                        tabs={tabs}
                        // title="Create Camaignddd"
                        indicatorIcon={arrowRightExtended}
                    />
                </Drawer>
            </div>
            
            <div className={`campaigns-wizard campaigns-wizard-${campaignWizardStatus}`}>
                <Drawer
                    display={showzoneWizardDrawer1}
                    setDisplay={setShowzoneWizardDrawer1}
                    // tooltipContent={`${
                    //     balance.toFixed(0) > 0
                    //         ? "Launch a new ad campaign."
                    //         : "“Top up your account balance before launching a campaign”."
                    // }`}
                    // disabled={balance.toFixed(0) > 0 ? false : true}
                    toggler={
                        <Button
                            name="Edit Website"
                            variant="contained" 
                            startIcon={plus}
                            color="success"
                            className="openeditzone hide"
                            style={{ display: "none" }}
                            // disabled={ balance.toFixed(0) > 0 ? false : true}
                        />
                    }
                    className="drawer-right drawer-fullscreen"
                    callback={() => {
                        setCampaignWizardStatus("Edit");
                        setCampaignState(defaultCampaign);
                        setSelectedImages([]);
                        setCreatives([]);
                        setCampaignTabs({
                            clickable: [],
                            marked: [],
                            disabled: [],
                        });
                    }} 
                >
                    {campaignWizardStatus === "create" && balance <= 0 && (
                        <marquee direction="left">
                            <div className="alert alert-warning">
                                {alert} “Top up your account balance before launching a campaign”.
                            </div>
                        </marquee>
                    )}
                    <h2 className="campaigns-wizard-title display-2">
                        {campaignWizardStatus == "create" ? "add new" : "edit"} Website
                    </h2>
                    <Tabs
                        tabsProps={{
                            campaignWizardStatus,
                            setCampaignWizardStatus,
                        }}
                        tabs={tabs1}
                        // title="Create Camaignddd"
                        indicatorIcon={arrowRightExtended}
                    />
                </Drawer>
            </div>
        </> 
    );
}

// MODAL CHILD
const ASPECT_RATIO = 3 / 2;
const MIN_DIMENSTION = 150;

const ModalChild = ({ setIsOpen }) => {
    const [crop, setCrop] = useState();
    const imageRef = useRef(null);
    const canvasRef = useRef(null);

    const { placeholder } = localData.images;
    const { close } = localData.svgs;
    const { setSelectedImages, campaignsState } = useGlobalContext();
    const { creativesState, setCreativesState } = campaignsState;

    const closeModal = () => {
        setIsOpen(false);
        // setPlaceholders((prev) => ({
        //     ...prev,
        //     editProfile: {
        //         ...prev.editProfile,
        //         modalImage: null,
        //     },
        // }));
    };

    const handleImageCrop = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSTION / width) * 100;
        // const cropHeightInPercent = (MIN_DIMENSTION / height) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%", // Can be 'px' or '%'
                width: width,
            },
            ASPECT_RATIO,
            width,
            height
        ); 

        const centeredCrop = centerCrop(crop, width, height);

        setCrop(centeredCrop);
    };

    const { setCanvasPreview } = useCrop();
    const { getFileFromCanvas, resizeFile } = useUtil();

    function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    const updateImages = (dataURL, file) => {
        resizeFile(file, 900, 600)
            .then((resizedFile) => {
                fileToBase64(resizedFile)
                    .then((resizedDataURL) => {
                        let item = {
                            file: resizedFile,
                            preview: URL.createObjectURL(resizedFile),
                            image: resizedDataURL,
                            id: uuidv4(),
                            status: 1,
                            title: resizedFile.name,
                            size: resizedFile.size,
                            type: getImageTypeFromBase64(resizedDataURL), // Store the image type
                        };

                        setSelectedImages((prevImages) => [...prevImages, item]);
                        closeModal();
                    })
                    .catch((error) => {
                        // Handle error
                        console.error("Error converting file to Base64:", error);
                    });
            })
            .catch((error) => {
                console.error("Error resizing image:", error);
            });
    };

    const getImageTypeFromBase64 = (base64String) => {
        const base64HeaderRegex = /^data:(image\/\w+);base64,/;
        const match = base64String.match(base64HeaderRegex);
        if (match && match[1]) {
            return match[1]; // Extracted image type (e.g., "image/jpeg", "image/png")
        } else {
            return null; // Image type not found
        }
    };

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">Crop Image</h5>
                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>

            <div className="modal-body">
                <ReactCrop
                    crop={crop}
                    onChange={(pixelCrop, percentCrop) => {
                        setCrop(percentCrop);
                    }}
                    // circularCrop
                    keepSelection
                    aspect={ASPECT_RATIO}
                    minWidth={MIN_DIMENSTION}
                    style={{ display: true ? "block" : "none" }}
                >
                    <img
                        ref={imageRef}
                        className={`file-image`}
                        src={creativesState.uploadedImage || placeholder}
                        alt="Selected Image"
                        // style={{ maxHeight: "70vh" }}
                        onLoad={(e) => handleImageCrop(e)}
                    />
                </ReactCrop>
            </div>

            <div className="modal-footer">
                {crop && (
                    <canvas
                        ref={canvasRef}
                        style={{
                            display: "none",
                            border: "1px solid",
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                        }}
                    />
                )}
                <Button
                    // disabled={!placeholders.editProfile.modalImage}
                    // disabled={isCropDisabled}
                    // style={{ marginRight: "10px" }}
                    variant="contained"
                    color="primary"
                    name="Crop Image"
                    onClick={async () => {
                        setCanvasPreview(
                            imageRef.current,
                            canvasRef.current,
                            convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
                        );
                        try {
                            console.log(imageRef.current.type);
                            const file = await getFileFromCanvas(
                                canvasRef.current,
                                getImageTypeFromBase64(imageRef.current.src)
                            );
                            const dataURL = canvasRef.current.toDataURL();
                            updateImages(dataURL, file);
                        } catch (error) {
                            console.error("Error generating file from canvas:", error);
                        }
                        // console.log(canvasRef.current.toDataURL())
                        // try {
                        //     const file = await getFileFromCanvas();
                        //     const dataURL = canvasRef.current.toDataURL();
                        //     (dataURL, file);
                        // } catch (error) {
                        //     console.error("Error generating file from canvas:", error);
                        // }
                    }}
                />
            </div>
        </>
    );
};
