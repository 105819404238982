import React, { useState, useEffect } from "react";
import { Field, Switch, Button, Carousel, AdsCard, UModal } from "../../../components";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { auth } from "../../../config/firebase";
import useFetch from "../../../hooks/useFetch";
import { Tooltip } from "react-tooltip";
import { postAPIwithAuth } from "../../../services/webService";

export default function Zonecreation({ setActiveTab, campaignWizardStatus, setCampaignWizardStatus }) {
    const { budget, date, arrowLeft, arrowRight } = localData.svgs;
    const { image1, image2, image3, image4, preloaderBig } = localData.images;
    const [formData, setFormData] = useState({
        zonename: 'My Zone',
        zonesize: "300X250"
    });
    const { errorAlert, successAlert, getLoadCampaignData, campaignState, creatives } = useGlobalContext();
    const { ensureTokenValid, isTokenExpired, updateTokens, refreshAccessToken } = useGlobalContext();
    const { balance } = "0";
    var payment_unit;

    const [requestedData, setRequestedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [errors, setErrors] = useState({});
    const { createNewCampaign, editCampaign } = useFetch();
    var parsedUser;

    function filterEmptyProps(obj) {
        const filtered = {};
        for (const key in obj) {
            if (obj[key] !== "" && obj[key] !== null) {
                filtered[key] = obj[key];
            }
        }
        return filtered;
    }

    //useEffect(() => {});

    const publisher_id = JSON.parse(localStorage.getItem("created_publisher_id"));

    payment_unit = localStorage.getItem("paymentunitselected");
    const { setDisplayCreativesModal } = campaignState;

    const createZone = async (formData) => {
        let newFormData = formData;

        newFormData.affiliateid = publisher_id;
        const splitParts = formData.zonesize.split("X").map((part) => part.trim());
        newFormData.delivery = formData.zonetype;
        newFormData.width = splitParts["0"];
        newFormData.height = splitParts["1"];
        try {
            const responseData = await postAPIwithAuth(`publisher/zone-create.php`, newFormData);
            setIsLoading(false);
            setTimeout(()=>{

                setActiveTab(2);
            },1000)

            //setFormData(initialFormData);
            successAlert('Your zones settings have been saved.')
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (validateForm()) {
            createZone(formData);
        }
    };

    const handleChange = (e) => {
        validateForm();
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.zonename) {
            newErrors.zonename = "Zone Name is required";
            isValid = false;
        }

        if (!formData.zonesize) {
            newErrors.zonesize = "Zone Size is required";
        }
        setErrors(newErrors);
        return isValid;
    };
    const handleAnotherButtonClick = () => {
        // Call the submit handler directly
        handleSubmit(new Event("submit"));
    };
    const handlecreateCampaign = async () => {
        setIsLoading(true);
        const user = localStorage.getItem("user");
        parsedUser = JSON.parse(user);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT}/publisher/addwebsite.php?access_token=` +
                    parsedUser.access_token,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        formValues: {
                            name: campaignState.campaignInfo.campaignName,
                            website: campaignState.campaignInfo.targetURL,
                            comments: "",
                            Vertical: "Crypto Trading",
                            user_id: parsedUser.user_id,
                            payment_unit: payment_unit,
                            unit_price: "0.1",
                        },
                    }),
                }
            );
            const data = await response.json();
            if (!data.status) {
                errorAlert(data.result);
                setIsLoading(false);
            } else {
                //setRequestedData(data.publisher_id);
                localStorage.removeItem("created_publisher_id");
                localStorage.setItem("created_publisher_id", data.publisher_id);

                // const zone1response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-create.php?access_token=`+parsedUser.access_token, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         "zonename":campaignState.campaignInfo.campaignName+"_900x600",
                //         "affiliateid":data.publisher_id,
                //         "width":"900",
                //         "height":"600"
                //     }),
                // });

                // const zone3response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-create.php?access_token=`+parsedUser.access_token, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         "zonename":campaignState.campaignInfo.campaignName+"_320x50",
                //         "affiliateid":data.publisher_id,
                //         "width":"320",
                //         "height":"50"
                //     }),
                // });

                setIsLoading(false);
                setActiveTab(2);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            if (error.name === "TypeError" && error.message === "Failed to fetch") {
                await updateTokens();
                setIsLoading(false);
                errorAlert("Failed to create website please try again");
            } else {
                console.error("An error occurred:", error);
            }
        }

        // // const filteredCampaignInfo =  filterEmptyProps(campaignState.campaignInfo)
        // // const filteredCampaignState = {...campaignState,campaignInfo:filteredCampaignInfo}

        // const filteredCampaignState = { ...campaignState };
        // const isEndDateExist = filteredCampaignState.campaignInfo.endDate;
        // if (!isEndDateExist) delete filteredCampaignState.campaignInfo.endDate;

        // const isChainTargetingExist = filteredCampaignState.campaignInfo.chainTargeting.length;
        // if (!isChainTargetingExist) filteredCampaignState.campaignInfo.chainTargeting = ["BSC"];

        // const isCountiesTargetingExist = filteredCampaignState.campaignInfo.countriesTargeting.length;
        // if (!isCountiesTargetingExist)
        //     filteredCampaignState.campaignInfo.countriesTargeting = [
        //         "ad", "af", "ag", "ai", "al", "am", "an", "ao", "aq",
        //         "ar", "as", "at", "au", "aw", "ax", "az", "ba", "bb", "bd",
        //         "be", "bf", "bg", "bh", "bi", "bj", "bl", "bm", "bn", "bo",
        //         "bq", "br", "bs", "bt", "bv", "bw", "by", "bz", "ca", "cc",
        //         "cd", "cf", "cg", "ch", "ci", "ck", "cl", "cm", "cn", "co",
        //         "cr", "cu", "cv", "cw", "cx", "cy", "cz", "de", "dj", "dk",
        //         "dm", "do", "dz", "ec", "ee", "eg", "eh", "er", "es", "et",
        //         "fi", "fj", "fk", "fm", "fo", "fr", "ga", "gb", "gd", "ge",
        //         "gf", "gg", "gh", "gi", "gl", "gm", "gn", "gp", "gq", "gr",
        //         "gs", "gt", "gu", "gw", "gy", "hk", "hm", "hn", "hr", "ht",
        //         "hu", "id", "ie", "il", "im", "in", "io", "iq", "ir", "is",
        //         "it", "je", "jm", "jo", "jp", "ke", "kg", "kh", "ki", "km",
        //         "kn", "kp", "kr", "kw", "ky", "kz", "la", "lb", "lc", "li",
        //         "lk", "lr", "ls", "lt", "lu", "lv", "ly", "ma", "mc", "md",
        //         "me", "mf", "mg", "mh", "mk", "ml", "mm", "mn", "mo", "mp",
        //         "mq", "mr", "ms", "mt", "mu", "mv", "mw", "mx", "my", "mz",
        //         "na", "nc", "ne", "nf", "ng", "ni", "nl", "no", "np", "nr",
        //         "nu", "nz", "om", "pa", "pe", "pf", "pg", "ph", "pk", "pl",
        //         "pm", "pn", "pr", "ps", "pt", "pw", "py", "qa", "re", "ro",
        //         "rs", "ru", "rw", "sa", "sb", "sc", "sd", "se", "sg", "sh",
        //         "si", "sj", "sk", "sl", "sm", "sn", "so", "sr", "ss", "st",
        //         "sv", "sx", "sy", "sz", "tc", "td", "tf", "tg", "th", "tj",
        //         "tk", "tl", "tm", "tn", "to", "tr", "tt", "tv", "tw", "tz",
        //         "ua", "ug", "um", "us", "uy", "uz", "va", "vc", "ve", "vg",
        //         "vi", "vn", "vu", "wf", "ws", "ye", "yt", "za", "zm", "zw",
        //       ]
        // const organizationID = requestedData.userProfileData.currentOrganization.id;
        // try {
        //     const data = await createNewCampaign(() => {}, filteredCampaignState, organizationID);
        //     successAlert(data.res_msg);

        //     const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        //     getLoadCampaignData(
        //         requestedData.userProfileData.currentOrganization.id,
        //         1,
        //         campaignsStates?.pageSize || 10
        //     );

        //     const campaignsDrawerClose = document.querySelector(".campaigns-drawer-close");
        //     if (campaignsDrawerClose) campaignsDrawerClose.click();
        // } catch (err) {
        //     let errorMessage = err?.response?.data?.res_msg;
        //     if (typeof errorMessage !== "string") errorMessage = err?.response?.data?.res_msg?.status[0];
        //     errorAlert(errorMessage || "Internal Server Error");
        //     console.error(err, "=createNewCampaign= request error");
        // }

        //alert("create website");
    };

    const handleEditCampaign = async () => {
        // setIsLoading(true);
        // const filteredCampaignState = {
        //     uuid: campaignState.uuid,
        //     organizationID: requestedData.userProfileData.currentOrganization.id,
        //     creatorEmail: campaignState.creatorEmail,
        //     campaignId: campaignState.campaignId,
        //     newCampaignInfo: {
        //         name: campaignState.campaignInfo.campaignName,
        //         limit_daily_amount: campaignState.campaignInfo.budget,
        //         endDate: campaignState.campaignInfo.endDate,
        //         targetURL: campaignState.campaignInfo.targetURL,
        //         smartContractAddress: campaignState.campaignInfo.smartContractAddress,
        //         // maxCPC: campaignState.campaignInfo.maxCPC,
        //         category: campaignState.campaignInfo.category,
        //         web3Audience: campaignState.campaignInfo.web3Audience,
        //         chainTargeting: campaignState.campaignInfo.chainTargeting,
        //         campaignType: campaignState.campaignInfo.campaignType,
        //         walletTargeting: campaignState.campaignInfo.walletTargeting,
        //         deviceType: campaignState.campaignInfo.deviceType,
        //         operativeSystem: campaignState.campaignInfo.operativeSystem,
        //         browser: campaignState.campaignInfo.browser,
        //         countriesTargeting: campaignState.campaignInfo.countriesTargeting,
        //     },
        // };
        // const isEndDateExist = filteredCampaignState.newCampaignInfo.endDate;
        // if (!isEndDateExist) delete filteredCampaignState.newCampaignInfo.endDate;
        // const isNewCreativeExist = campaignState.creatives.find((creative) => !creative.id);
        // if (isNewCreativeExist) {
        //     filteredCampaignState.creatives = campaignState.creatives.map((creative) => {
        //         return {
        //             title: creative.title,
        //             image: creative.image,
        //             id: creative.id?.toString() || "new",
        //         };
        //     });
        // }
        // // console.log(filteredCampaignState)
        // try {
        //     const data = await editCampaign(() => {}, filteredCampaignState);
        //     console.log(data, " editCampaign request");
        //     successAlert(data.res_msg);
        //     const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        //     getLoadCampaignData(
        //         requestedData.userProfileData.currentOrganization.id,
        //         1,
        //         campaignsStates?.pageSize || 10
        //     );
        //     const campaignsDrawerClose = document.querySelector(".campaigns-drawer-close");
        //     if (campaignsDrawerClose) campaignsDrawerClose.click();
        // } catch (err) {
        //     let errorMessage = err?.response?.data?.res_msg;
        //     if (typeof errorMessage !== "string") errorMessage = err?.response?.data?.res_msg?.message;
        //     errorAlert(errorMessage || "Internal Server Error");
        //     console.error(err, "=editUser= request error");
        // }
        // setIsLoading(false);
        //alert("edit website");
    };

    const getSummaryItems = (property) => {
        return !campaignState.campaignInfo[property].length
            ? "..."
            : campaignState.campaignInfo[property].map((item, index) => (
                  <span key={index}>
                      {item}
                      {index !== campaignState.campaignInfo[property].length - 1 && ", "}
                  </span>
              ));
    };

    // const handleViewCampaignInfo = async () => {
    //     const infoRaw = {
    //         uuid: campaignState.uuid,
    //         campaignId: campaignInfo.id,
    //     };
    //     try {
    //         const data = await viewCampaignInfo(() => {}, infoRaw);
    //         console.log(data, " viewCampaignInfo request");
    //         successAlert(data.res_msg);
    //     } catch (err) {
    //         errorAlert(err.response?.data?.res_msg || "error");
    //         console.log(err, "error triggered in Summary.jsx");
    //     }
    // };

    return (
        <div className="summary">
            <div className="wrapper tab-content-center tab-content-creatives">
                {/* <h2 className="summary-title display-2">New website summary</h2> */}
                <div className="zone-creation-description">Choose your zone settings.</div>

                <div className="row row-top">
                    <div className="col col-left">
                        <form onSubmit={handleSubmit}>
                            <div className="field ">
                                <div className="wrapper">
                                    <label className="form-label">
                                        Zone Name *{" "}
                                        <small className="text-danger">
                                            {isSubmitted && errors.zonename && <p>{errors.zonename}</p>}
                                        </small>
                                    </label>
                                </div>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="zonename"
                                        id="zonename"
                                        className="form-control form-control-secondary  form-control-sm"
                                        placeholder="e.g. johnDoeCamp"
                                        value={formData.zonename}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="field ">
                                <div className="wrapper">
                                    <label className="form-label">Zone Type *</label>
                                </div>
                                <div className="input-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="1"
                                            name="zonetype"
                                            id="zonetype"
                                            onChange={handleChange}
                                            checked={true}
                                            required
                                        />
                                        <span>Image</span>
                                    </label>{" "}
                                    <p className="description">Image zones include animated gif ads.</p>
                                    <br />

                                    <label>
                                        <input
                                            type="radio"
                                            value="10"
                                            name="zonetype"
                                            id="zonetype"
                                            onChange={handleChange}
                                            required
                                        />
                                        <span>Video</span>
                                    </label>
                                </div>
                                {isSubmitted && errors.zonetype && <p>{errors.zonetype}</p>}
                            </div>
                            <div className="field ">
                                <div className="wrapper">
                                    <label className="form-label w-full">
                                        Zone Size *{" "}
                                        <small className="text-danger">
                                            {errors.zonesize && <p>{errors.zonesize}</p>}
                                        </small>
                                    </label>
                                </div>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-secondary form-control-sm"
                                        value={formData.zonesize}
                                        name="zonesize"
                                        id="zonesize"
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Zone Size</option>
                                        <option value="300X250">Medium Rectangle Banner (300 X 250)</option>
                                        <option value="320X100">Large Mobile Banner (320 X 100)</option>
                                        <option value="728X90">Leader Board Banner (728 X 90)</option>
                                        <option value="320X50">Smartphone Banner (320 X 50)</option>
                                    </select>
                                </div>
                            </div>
                        </form>

                        {/* <div className="wrapper">
                            <div className="th">Smart Contract</div>
                            <div className="td">
                                {campaignState.campaignInfo.smartContractAddress || "..."}
                            </div>
                        </div> */}

                        {/* <div className="wrapper">
                            <div className="th">Budget </div>
                            <div className="td">${campaignState.campaignInfo.budget || "..."}</div>
                        </div> */}

                        {/* <div className="wrapper">
                            <div className="th">Payment Unit </div>
                            <div className="td">{ payment_unit || "..."}</div>
                        </div> */}

                        {/* <div className="wrapper">
                            <div className="th">Max CPC</div>
                            <div className="td">${campaignState.campaignInfo.maxCPC || "..."}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Start Date</div>
                            <div className="td">{campaignState.campaignInfo.startDate || "..."}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">End Date</div>
                            <div className="td"> {campaignState.campaignInfo.endDate || "..."}</div>
                        </div>
                        <div className="wrapper">
                            <div className="th">Campaign Type</div>
                            <div className="td">{getSummaryItems("campaignType")} </div>
                        </div> */}
                    </div>

                    {/* <div className="col col-right">
                        <div className="title">Targeting</div>

                        <div className="wrapper">
                            <div className="th">Category</div>

                            <div className="td">{getSummaryItems("category")}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Web3 Audiences</div>
                            <div className="td"> {getSummaryItems("web3Audience")} </div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Operative System</div>
                            <div className="td">{getSummaryItems("operativeSystem")}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Geo</div>
                            <div className="td">{getSummaryItems("countriesTargeting")}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Wallet Targeting</div>
                            <div className="td">{getSummaryItems("walletTargeting")}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Chain Targeting</div>
                            <div className="td">{getSummaryItems("chainTargeting")}</div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Browser</div>
                            <div className="td"> {getSummaryItems("browser")} </div>
                        </div>

                        <div className="wrapper">
                            <div className="th">Device Type</div>
                            <div className="td">{getSummaryItems("deviceType")}</div>
                        </div>
                    </div> */}
                </div>

                <hr />
                <br />
                <br />

                {/* <div className="row row-creatives">
                    {!creatives.length ? (
                        ""
                    ) : (
                        <>
                            <h2 className="carousel-title displaly-2">Ads</h2>

                            <Carousel
                                items={creatives}
                                Card={AdsCard}
                                cardOptions={{ readOnly: true }}
                                options={{
                                    spaceBetween: 10,
                                    autoplay: false,
                                    loop: false,
                                    breakpoints: {
                                        640: {
                                            slidesPerView: 2,
                                        },
                                        1360: {
                                            slidesPerView: 3,
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                </div> */}

                <div className="row row-bottom btn-group">
                    <Button
                        size="sm"
                        startIcon={arrowLeft}
                        name="Back"
                        variant="text"
                        color="dark"
                        onClick={() => setActiveTab((prevNumber) => prevNumber - 1)}
                    />
                    <div
                        className={`${
                            campaignWizardStatus === "create" && balance <= 0 ? "not-editable" : ""
                        }`}
                        data-tooltip-id="tooltip-submit-btn"
                        data-tooltip-content={
                            campaignWizardStatus === "create" && balance <= 0
                                ? "“Top up your account balance before launching a campaign”."
                                : ""
                        }
                    >
                        <Button
                            className="submit-btn"
                            size="sm"
                            name={campaignWizardStatus === "create" ? "Save & Continue" : "Edit & Continue"}
                            variant="contained"
                            color={campaignWizardStatus === "create" ? "success" : "warning"}
                            // onClick={
                            //     campaignWizardStatus === "create" ? handlecreateCampaign : handleEditCampaign
                            // }
                            onClick={handleAnotherButtonClick}
                            icon={
                                isLoading ? (
                                    <span className="endIcon">
                                        <img src={preloaderBig} />
                                    </span>
                                ) : null
                            }
                            disabled={balance <= 0 || isLoading}
                        />
                        <Tooltip id="tooltip-submit-btn" className="custom-tooltip" />
                    </div>
                </div>
            </div>
        </div>
    );
}
