import React, { useState, useEffect, useRef } from "react";
import { Field, Switch, Button, MultiSelect, Select, Modal } from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import useFormatter from "../../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import TrackingHelp from "../../../pages/tracking/tracking-help/TrackingHelp";

let parsedUser = null
let payment_unit = null

export default function CampaignInformation({ setActiveTab }) {
    const {
        formatDate,
        getDelayedDate,
        // formatNumber,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
        successAlert,
        errorAlert,
        updateTokens
    } = useGlobalContext();
    const { formatNumber, formatCurrency, addCommas } = useFormatter();
    const { campaignTabs, setCampaignTabs } = campaignsState;

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    // const setDefaultOptions = ({ property, state, setState }) => {
    //     let tempState = [...state];
    //     tempState = tempState.map((item) => {
    //         const _property = campaignState.campaignInfo[property]
    //         if(!_property) return
    //         const isSelected = _property.includes(item.title);
    //         return { ...item, isSelected };
    //     });
    //     setState(tempState);
    // };

    // useEffect(() => {
    //     // let tempCampaignTypeOptions = [...campaignTypeOptions];
    //     // tempCampaignTypeOptions = tempCampaignTypeOptions.map((item) => {
    //     //     const isSelected = campaignState.campaignInfo.campaignType.includes(item.title);
    //     //     return { ...item, isSelected };
    //     // });
    //     // setCampaignTypeOptions(tempCampaignTypeOptions);
    //     setDefaultOptions({
    //         property: "chainTargeting",
    //         state: chainTargetingOptions,
    //         setState: setChainTargetingOptions,
    //     });
    // }, []);

    const [state, setState] = useState({
        campaignName: campaignState.campaignInfo.campaignName,
        targetURL: campaignState.campaignInfo.targetURL,
        // smartContractAddress: campaignState.campaignInfo.smartContractAddress,
        budget: campaignState.campaignInfo.budget,
        maxCPC: campaignState.campaignInfo.maxCPC,
        startDate: campaignState.campaignInfo.startDate,
        endDate: campaignState.campaignInfo.endDate,
        paymentunit: campaignState.campaignInfo.paymentunit || "",
        campaignType: [],
    });
    //console.log(state.paymentunit);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const { budget } = localData.svgs;

    function checkIsValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // const handleMultiSelectChange = (items, name) => {
    //     let selectedItem = items?.filter((item) => item.isSelected);
    //     selectedItem = selectedItem?.map((item) => item.title);

    //     setState((state) => ({ ...state, [name]: selectedItem }));
    // };
    const handleSelectChange = (updatedItems, name) => {
        localStorage.setItem("paymentunitselected", updatedItems.title);

        if (!Array.isArray(updatedItems)) {
            console.error("items is not an array:", updatedItems.title);
            return;
        }
        console.log(updatedItems);
        let selectedItem = updatedItems.find((item) => item.isSelected);

        if (!selectedItem) {
            console.error("No item is selected in items:", updatedItems);
            return;
        }

        setState((state) => ({ ...state, [name]: selectedItem.title }));
    };

    const [errors, setErrors] = useState({
        campaignNameError: false,
        targetURLError: false,
        // smartContractAddressError: false,
        budgetError: false,
        startDateError: false,
        endDateError: false,
    });

    useEffect(() => {
        // console.log(Date.now(), " - date now");
        // console.log(new Date(startDateRef.current.value).getTime(), " start date");

        setErrors({
            ...errors,
            campaignNameError: state.campaignName === "",
            targetURLError: state.targetURL === "" || !checkIsValidUrl(state.targetURL),
            // smartContractAddressError:
            //     state.smartContractAddress !== "" && !checkIsBlockchainAddress(state.smartContractAddress),
            budgetError: state.budget < 330,
            // startDateError: state.startDate === "",
            // campaignTypeError: !state.campaignType.length,
        });
    }, [state]);

    // useEffect(() => {
    //     console.log(errors, " errors");
    // }, [errors]);

    useEffect(() => {
        const haveError =
            !errors.campaignNameError &&
            !errors.targetURLError &&
            // !errors.smartContractAddressError &&
            !errors.budgetError;
        setIsButtonDisabled(!haveError);
    }, [errors]);

    function checkIsBlockchainAddress(text) {
        var pattern = /^0x[a-fA-F0-9]+$/;
        return pattern.test(text);
    }

    const [campaignTypeOptions, setCampaignTypeOptions] = useState([
        { title: "Awareness (max reach)", isSelected: false, id: uuidv4() },
        { title: "Engagement (website visit/interaction)", isSelected: false, id: uuidv4() },
        { title: "Conversion (website download/transactions/sales)", isSelected: false, id: uuidv4() },
    ]);

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    const setDefaultOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    useEffect(() => {
        setDefaultOptions({
            property: "campaignType",
            state: campaignTypeOptions,
            setState: setCampaignTypeOptions,
        });
        setDefaultOptions({ property: "category", state: categoryOptions, setState: setCategoryOptions });
    }, []);
    const [data, setData] = useState(undefined);

    const paymentUnitOptions = [
        { value: "cpm", title: "CPM", isSelected: false, id: uuidv4() },
        { value: "cpc", title: "CPC", isSelected: false, id: uuidv4() },
        { value: "cpa", title: "CPA", isSelected: false, id: uuidv4() },
    ];

    const options = ["CPM", "CPC", "CPA"];
    const onOptionChangeHandler = (event) => {
        setData(event.target.value);
        console.log("User Selected Value - ", event.target.value);
    };

    const [categoryOptions, setCategoryOptions] = useState([
        { title: "Decentralized Finance", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Blockchain Gaming", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Marketplace", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Infrastructure", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "NFT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "DEX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "CEX", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Other", isSelected: false, size: "", id: uuidv4() },
    ]);

    const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");
    const [items, setItems] = useState(paymentUnitOptions);


    payment_unit = localStorage.getItem("paymentunitselected");

    const handlecreateCampaign = async ({campaignInfo}) => {
        // setIsLoading(true);
        const user = localStorage.getItem("user");
        parsedUser = JSON.parse(user);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT}/publisher/addwebsite.php?access_token=` +
                    parsedUser.access_token,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        formValues: {
                            name: campaignInfo.campaignName,
                            website: campaignInfo.targetURL,
                            comments: "",
                            Vertical: "Crypto Trading",
                            user_id: parsedUser.user_id,
                            payment_unit: payment_unit,
                            unit_price: "0.1",
                        },
                    }),
                }
            );
            const data = await response.json();
            if (!data.status) {
                errorAlert(data.result);
                // setIsLoading(false);
            } else {
                //setRequestedData(data.publisher_id);
                localStorage.removeItem("created_publisher_id");
                localStorage.setItem("created_publisher_id", data.publisher_id);


                // setIsLoading(false);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            if (error.name === "TypeError" && error.message === "Failed to fetch") {
                await updateTokens();
                // setIsLoading(false);
                errorAlert("Failed to create website please try again");
            } else {
                console.error("An error occurred:", error);
            }
        }

    };

    return (
        <div className="campaign-information">
            <div className="wrapper tab-content-center">
                <div className="campaign-information-description">
                    Submit your website info and choose an ad category.
                </div>

                <div className="row row-top">
                    <div className="col col-left">
                        <Field
                            type="text"
                            label="Website Name *"
                            labelInfo="Choose a name for your website. Make sure to pick a unique name to better recognize your website later."
                            name="campaignName"
                            value={state.campaignName}
                            color="secondary"
                            placeholder="e.g. johnDoeCamp"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                        />

                        <Field
                            type="text"
                            label="Website URL*"
                            labelInfo="Select a Landing Page for your ads. We recommend using UTM Parameters to track the performance of your campaigns."
                            name="targetURL"
                            value={state.targetURL}
                            color="secondary"
                            placeholder="e.g. https://johndoe.com"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                            errorMessage={
                                state.targetURL !== "" && !checkIsValidUrl(state.targetURL)
                                    ? `Invalid URL. Please enter a valid URL. e.g.
                                <br /> https://johndoe.com`
                                    : ""
                            }
                        />

                        {/* <Field
                            type="text"
                            label="Smart Contract"
                            labelInfo="(Optional) Select the Smart Contract of your project, so аre able to track transactions."
                            color="secondary"
                            placeholder="e.g. 0x00000000000000"
                            required={true}
                            size="sm"
                            name="smartContractAddress"
                            value={state.smartContractAddress}
                            callback={handleOnChange}
                            errorMessage={
                                state.smartContractAddress !== "" &&
                                !checkIsBlockchainAddress(state.smartContractAddress)
                                    ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                                    : ""
                            }
                        /> */}

                        <MultiSelect
                            {...{
                                items: categoryOptions,
                                setItems: setCategoryOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Category",
                                labelInfo: "(Optional) Select your project’s vertical.",
                                name: "category",
                                callback: handleMultiSelectChange,
                            }}
                        />

                        {/* <Field
                            type="text"
                            label="Budget (USD/Day)*"
                            labelInfo="Choose how much you want to spend each day. Minimum is $330"
                            name="budget"
                            value={state.budget}
                            color="secondary"
                            placeholder="Budget" 
                            required={true}
                            size="sm"
                            startIcon={budget}
                            callback={handleOnChange}
                            errorMessage={errors.budgetError && "Monthly budget must be at least $330."}
                        /> */}
                        {/* <Select
                            label="Payment Unit*"
                            items={items} 
                            setItems={setItems}
                            placeholder="Choose which payment unit (CPM, CPC, CPA)"
                            className=""
                            variant="outlined"
                            color="secondary"
                            size="md"
                            isLoading={false}
                            callback={(updatedItems) => handleSelectChange(updatedItems,"paymentoption")}
                        /> */}
                    </div>
                </div>

                <div className="row row-bottom btn-group">
                    <Modal
                        Child={InstallPixelModal}
                        className="install-pixel-modal tracking-modal modal-dialog-centered"
                        display={showInstallPixelModal}
                        setDisplay={setShowInstallPixelModal}
                        restProps={{ setActiveTab,handlecreateCampaign }}
                        Toggler={() => (
                            <Button
                                size="sm"
                                disabled={isButtonDisabled}
                                name="Save & Continue"
                                variant="contained"
                                color={isDarkModeEnabled ? "light" : "dark"}
                                onClick={() => {
                                    setCampaignState((prev) => {
                                        return {
                                            ...prev,
                                            campaignInfo: {
                                                ...prev.campaignInfo,
                                                ...state,
                                                budget: state.budget.toString().replace(/,/g, ""),
                                                maxCPC: state.maxCPC.toString().replace(/,/g, ""),
                                                startDate: state.startDate || formatDate(),
                                                endDate: state.endDate,
                                                // endDate:
                                                //     state.endDate ||
                                                //     formatDate(getDelayedDate(startDateRef?.current?.value, 30)),
                                                // smartContractAddress: state.smartContractAddress,
                                                // smartContractAddress:
                                                //     state.smartContractAddress ||
                                                //     "0x0000000000000000000000000000000000001000",
                                            },
                                        };
                                    });
                                    setShowInstallPixelModal('block')
                                }}
                            />
                        )}
                    ></Modal>
                </div>
            </div>
        </div>
    );
}

const InstallPixelModal = ({ setIsOpen, setActiveTab, handlecreateCampaign }) => {
    const { conversionOptions, campaignsState,campaignState, successAlert,errorAlert } = useGlobalContext();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                {/* need to confirm
                <br />
                <br /> */}
                <TrackingHelp />
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        className="next-step-btn"
                        name="Continue"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={(e) => {
                            closeModal();
                            handlecreateCampaign({...campaignState})
                            successAlert("Your website was created successfully.");
                            // setActiveTab(1);
                            setTimeout(() => {
                                setActiveTab((prevNumber) => prevNumber + 1);
                                setCampaignTabs({
                                    ...campaignTabs,
                                    clickable: [...campaignTabs.clickable, 1],
                                    marked: [...campaignTabs.marked, 1],
                                });
                            }, 1000);
                        }}
                    />
                </div>
            </div>
        </>
    );
};
