import React, { useState, useEffect, useRef } from "react";
import {
    TableSkeleton,
    Select,
    Button,
    Dropdown,
    CampaignDetailsPopup,
    Popup,
    Pagination,
    CampaignsCalendar,
    Calendar,
    Modal,
    Checkbox,
} from "../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext.jsx";
import { useCampaignsContext } from "../../../campaignsContext.jsx";
import { auth } from "../../../config/firebase.js";
import useFetch from "../../../hooks/useFetch.js";
import CampaignInfo from "./campaign-info/CampaignInfo.jsx";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter.jsx";
import CampaignsStatiscticsCSV from "../statistics-csv/CampaignsStatiscticsCSV.jsx";
import ConfirmationDialog from "../../../components/confirmationDialog";
import { postAPIwithAuth } from "../../../services/webService";

let isStatExist = false;

export default function CampaignsTable() {
    // const [zonesData, setZonesData] = useState([]);

    const {
        getLoadCampaignData,
        isLoading,
        requestedData: {
            userProfileData: { currentOrganization },
            // zonesData: { currentOrganizationCampaigns },
        },
        campaignsState,
        ensureTokenValid,
        isTokenExpired,
        updateTokens,
        refreshAccessToken,
        websiteid,
        _getPublisherHourlyStatistics,
        _getUserData,
        zonesData,
        setZonesData,
        markedCampaigns,
        setMarkedCampaigns,
        balance,
        setBalance,
        _getStats,
        _getZones,
    } = useGlobalContext();

    const {
        campaignsPage,
        setCampaignsPage,
        campaignsPageSize,
        setCampaignsPageSize,
        campaignsTotalPages,
        setCampaignsTotalPages,
        campaignsItemsPerPage,
        setCampaignsItemsPerPage,
        campaignSelectionRange,
        setCampaignSelectionRange,
        handleCalendar,
    } = campaignsState;
    const { removeAlias, addCommas } = useFormatter();
    function formatDate(value = null) {
        const date = value !== null ? new Date(value) : new Date();
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // const { isCampaignsCalendarOpen, setIsCampaignsCalendarOpen, dateRange, selectionRange } =
    //     useCampaignsContext();

    const [organizationId, setOrganizationId] = useState(null);

    const campaignTableRef = useRef(null);

    const handlePagination = (nextPage = 0) => {
        setCampaignsPage(nextPage);
    };

    useEffect(() => {
        setCampaignsPageSize(campaignsItemsPerPage.find((item) => item.isSelected).title);
    }, [campaignsItemsPerPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    }, [campaignsPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, 1, campaignsPageSize);

        const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        localStorage.setItem(
            "campaignsStates",
            JSON.stringify({
                ...campaignsStates,
                pageSize: campaignsPageSize,
            })
        );
    }, [campaignsPageSize]);

    useEffect(() => {
        // const id = requestedData.userProfileData.currentOrganization.id;
        // if (!id) return;
        // setOrganizationId(id);
        // if(requestedData.zonesData.isRequested) return
        // getLoadCampaignData(id, 1, 10);
    }, []);

    const formatDateToYYYYMMDD = (date) => {
        let [month, day, year] = date.split("/");
        let formattedDate = `${year}-${day}-${month}`;
        return formattedDate;
    };

    // const fetchCampaignData = async () => {
    //     const user = localStorage.getItem("user");
    //     const parsedUser = JSON.parse(user);

    //     const endDate = new Date(); // today
    //     const startDate = new Date(endDate);
    //     startDate.setDate(endDate.getDate() - 6); // 7 days ago

    //     const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
    //         dateFrom: formatDate(startDate),
    //         dateTill: formatDate(endDate),
    //     };

    //     try {
    //         //await ensureTokenValid(); // Ensure the token is valid before making the request
    //         const response = await fetch(
    //             `${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` +
    //                 parsedUser.access_token,
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({
    //                     user_id: parsedUser.user_id,
    //                     start_date: formatDateToYYYYMMDD(selectionRangeFormatted.dateFrom),
    //                     end_date: formatDateToYYYYMMDD(selectionRangeFormatted.dateTill),
    //                 }),
    //             }
    //         );
    //         const result = await response.json();
    //         setZonesData((prev) => ({ ...prev, data: result.result })); // Store fetched data in state

    //         // setCampaignsTotalPages(Math.ceil(result.result.length / campaignsPageSize));
    //         console.log(result.result);
    //     } catch (error) {
    //         if (error.name === "TypeError" && error.message === "Failed to fetch") {
    //             await updateTokens();
    //             const user = await JSON.parse(localStorage.getItem("user"));
    //             const response = await fetch(
    //                 `${process.env.REACT_APP_API_ENDPOINT}/publisher/publishersget.php?access_token=` +
    //                     user.access_token,
    //                 {
    //                     method: "POST",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                     },
    //                     body: JSON.stringify({
    //                         user_id: parsedUser.user_id,
    //                         start_date: formatDateToYYYYMMDD(selectionRangeFormatted.dateFrom),
    //                         end_date: formatDateToYYYYMMDD(selectionRangeFormatted.dateTill),
    //                     }),
    //                 }
    //             );
    //             const result = await response.json();
    //             setZonesData(result.result); // Store fetched data in state

    //             // localStorage.removeItem("user"); // Remove user from localStorage
    //             // window.location.href = '/sign-in'; // Redirect to the login page
    //         } else {
    //             // Handle other errors
    //             console.error("An error occurred:", error);
    //         }
    //     }
    // };

    const handleRefresh = () => {
        // fetchCampaignData();
    };
    useEffect(() => {
        // fetchCampaignData();
        // _getPublisherHourlyStatistics(),
        // _getUserData();
        // fetchCampaignData();
        // _getUserData()
    }, []);

    const { settings, eyeSolid } = localData.svgs;
    const { textPreloader } = localData.images;

    // const formatDate = (date) => {
    //     const day = date.getDate().toString().padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear().toString();
    //     return `${day}/${month}/${year}`;
    // };

    // const handleCalendar = (range) => {
    //     const { startDate, endDate } = range;
    //     const dateFrom = formatDate(startDate);
    //     const dateTill = formatDate(endDate);
    //     localStorage.setItem("selectionRange", JSON.stringify(range));
    //     localStorage.setItem("selectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
    //     campaignsPage !== 1
    //         ? setCampaignsPage(1)
    //         : getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    //     setCampaignSelectionRange(range);
    // };

    // const handleCalendar = (range) => {
    //     const { startDate, endDate } = range;
    //     const dateFrom = formatDate(startDate);
    //     const dateTill = formatDate(endDate);
    //     localStorage.setItem("selectionRange", JSON.stringify(range));
    //     localStorage.setItem("selectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
    //     // campaignsPage !== 1
    //     //     ? setCampaignsPage(1)
    //     //     : getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, campaignsPage, campaignsPageSize);
    //     // setCampaignSelectionRange(range);
    //     // handleRefresh();
    //     _getZones();
    //     _getStats();
    // };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleMarkedCampaigns = (campaign, isMarked) => {
        let tempMarkedCampaigns = [...markedCampaigns];
        if (isMarked) {
            tempMarkedCampaigns = [...tempMarkedCampaigns, campaign];
        } else {
            tempMarkedCampaigns = tempMarkedCampaigns.filter((item) => item.id !== campaign.id);
        }

        setMarkedCampaigns(tempMarkedCampaigns);
    };

    const handleAllMarkedCampaigns = (isMarked) => {
        let tempMarkedCampaigns = [];
        // if (isMarked) tempMarkedCampaigns = [...currentOrganizationCampaigns];
        setMarkedCampaigns(tempMarkedCampaigns);
    };

    return (
        <div className="campaigns-table-wrapper" ref={campaignTableRef}>
            <div className="row">
                {/* <h2 className="display-2">My Websites</h2> */}
                {/* <Dropdown
                    {...{
                        title: dateRange,
                        variant: "text",
                        color: "dark",
                        className: "calendar-dropdown",
                        isOpen: isCampaignsCalendarOpen,
                        setIsOpen: setIsCampaignsCalendarOpen,
                        size: "sm",
                    }}
                >
                    <CampaignsCalendar
                        setIsOpen={setIsCampaignsCalendarOpen}
                        callback={handleCalendar}
                        {
                            ...{
                                // callback: callback,
                            }
                        }
                    />
                </Dropdown> */}
                <CampaignsStatiscticsCSV />
                <Calendar
                    className="reports-calendar"
                    _selectionRange={campaignSelectionRange}
                    callback={handleCalendar}
                />
            </div>
            <br />
            <div className={`scroll shadow }`} style={{ padding: 0 }}>
                {zonesData.isLoading ? (
                    <TableSkeleton message="Loading..." icon={textPreloader} />
                ) : (
                    <table id="campaigns-table" className="table campaigns-table">
                        <thead>
                            <tr className="table-row">
                                {/* <th style={{ width: "0" }}>
                                    <Checkbox
                                        callback={(isMarked) => handleAllMarkedCampaigns(isMarked)}
                                        checked={isChecked}
                                        color="primary"
                                        size="sm"
                                        someChecked={markedCampaigns.length && !isChecked}
                                    />
                                </th> */}
                                {/* <th className="table-th-details text-center">
                                
                                    <div
                                        className="details-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-details"
                                        data-tooltip-content={`See preview of the campaign details.`}
                                    >
                                        {eyeSolid}
                                    </div>
                                </th> */}

                                <th className="table-th-name text-start" style={{ width: "20%" }}>
                                    <span
                                        data-tooltip-id="tooltip-table-name"
                                        data-tooltip-content={`The name of your campaign.`}
                                    >
                                        Website
                                    </span>
                                </th>
                                <th
                                    className="table-th-settings text-center"
                                    // style={{ width: "20%" }}
                                    style={{ width: "0" }}
                                    // title="Activate or Pause your campaign"
                                >
                                    <div
                                        className="settings-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-settings"
                                        data-tooltip-content={`Activate or Pause your campaign.`}
                                    >
                                        {settings}
                                    </div>
                                </th>

                                {/* <th className="table-th-status text-center" style={{ width: "0" }}>
                                    <span
                                        data-tooltip-id="tooltip-table-status"
                                        data-tooltip-content={`The current status of your campaign.`}
                                    >
                                        Status
                                    </span>
                                </th> */}
                                <th className="table-th-impressions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                    >
                                        Impressions
                                    </span>
                                </th>
                                <th className="table-th-impressions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                    >
                                        Impressions RPM
                                    </span>
                                </th>
                                <th className="table-th-clicks text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                    >
                                        Clicks
                                    </span>
                                </th>

                                <th className="table-th-ad-spend text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                    >
                                        Page RPM
                                    </span>
                                </th>

                                <th className="table-th-conversions text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-conversions"
                                        data-tooltip-content={`Total amount of the conversions.`}
                                    >
                                        Estimated
                                        <br /> earnings*
                                    </span>
                                    <Tooltip id="tooltip-table-conversions" className="custom-tooltip" />
                                </th>
                                <th className="table-th-txn-value text-center">
                                    <span
                                        data-tooltip-id="tooltip-table-txn-value"
                                        data-tooltip-content={`Total amount of the transaction.`}
                                    >
                                        Active view <br /> viewable
                                    </span>
                                    <Tooltip id="tooltip-table-txn-value" className="custom-tooltip" />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!Array.isArray(zonesData.data) || zonesData.data.length === 0 ? (
                                <tr style={{ pointerEvents: "none" }}>
                                    <td colSpan="10">
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                zonesData.data.map((item, index) => (
                                    <CampaignRow
                                        key={index}
                                        item={item}
                                        index={index}
                                        handleMarkedCampaigns={handleMarkedCampaigns}
                                    />
                                ))
                            )}
                        </tbody>
                    </table>
                )}
                {!zonesData.isLoading && !zonesData.data.length && <div className="no-data-cover"></div>}
                <button className="refresh" onClick={handleRefresh} style={{ display: "none" }}>
                    {" "}
                    Refresh Data
                </button>
            </div>
            {/* {!Array.isArray(zonesData.data) || zonesData.data.length === 0 ? (
                ""
            ) : (
                <div className={`pagination-wrapper  ${zonesData.isLoading ? "on-hold" : ""}`}>
                    <Pagination
                        callback={handlePagination}
                        currentPage={campaignsPage}
                        totalPages={campaignsTotalPages}
                        isLoading={zonesData.isLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: campaignsItemsPerPage,
                                setItems: setCampaignsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                            }}
                        />
                    </div>
                </div>
            )} */}
        </div>
    );
}

// ===========================================================================================================
// const CAMPAIGN_STATUS = Object.freeze({
//     "Draft": 1,
//     "Moderation": 2,
//     "Rejected": 3,
//     "Ready": 4,
//     "Test run": 5,
//     "Working": 6,
//     "Paused": 7,
//     "Stopped": 8,
//     "Completed": 9,
//   });

// const showStopIcon = ["Working",'Paused · Daily Budget', "Moderation"];
// const showPlayIcon = [ "Stopped", "Draft", "Ready", "Test run"];

const showStopBtn = [6, 7, 2];
const showPlayBtn = [8, 1, 4, 5];

const CampaignRow = ({ item, index, handleMarkedCampaigns }) => {
    const { formatNumber, formatCurrency } = useFormatter();
    const {
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        campaignWizardStatus,
        setCampaignWizardStatus,
        errorAlert,
        successAlert,
        campaignState,
        setCampaignState,
        requestedData,
        activeCampaign,
        setActiveCampaign,
        activeInfoId,
        setActiveInfoId,
        isDarkModeEnabled,
        getLoadCampaignData,
        setCreatives,
        campaignsState,
        websiteid,
        conversionOptions: { advertiserPixelStats },
        markedCampaigns,
    } = useGlobalContext();
    const [activeTab, setActiveTab] = useState(0);
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { arrowDown, arrowUp, settings, play, stop, penV2, trashV2, close } = localData.svgs;
    const { removeAlias, formatDate, addCommas } = useFormatter();
    const [clickedId, setClickedId] = useState(null);

    // const [isPaused, setIsPaused] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState({
        isStopped: false,
        status: "unknown",
        statusNumber: null,
    });

    const [campaignBtns, setCampaignBtns] = useState({
        playBtn: {
            isReadOnly: true,
            message: "",
            isPlayBtnShown: false,
        },
        stopBtn: {
            isReadOnly: true,
            message: "",
            isStopBtnShown: false,
        },
        editBtn: {
            isReadOnly: true,
            message: "",
        },
        deleteBtn: {
            isReadOnly: true,
            message: "",
        },
    });

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setClickedId(null);
    };

    const handleConfirm = async () => {
        try {
            const responseData = await postAPIwithAuth(`publisher/deletewebsite.php`, {
                affiliateid: clickedId,
            });
            // console.log(responseData)
            handleCloseDialog();
            successAlert("Website deleted successfully!");
            // setZoneData(zoneData.filter(item => item.zoneid !== clickedId));

            sethideId(clickedId);
        } catch (error) {
            console.log(error);
            //LogoutService(navigate);
        }
    };

    useEffect(() => {
        const isEditBtnShown = true;
        const isEditBtnReadOnly = [].includes(campaignDetails.statusNumber);

        const isDeleteBtnShown = true;
        const isDeleteBtnReadOnly = [1].includes(campaignDetails.statusNumber);

        const isStopBtnShown = showStopBtn.includes(campaignDetails.statusNumber);
        const isStopBtnReadOnly = false;

        const isPlayBtnShown = showPlayBtn.includes(campaignDetails.statusNumber);
        const isPlayBtnReadOnly = false;

        // const isLowBalance = requestedData.userProfileData.currentOrganization.balance < 330;
        const isLowBalance = false;

        const message = (
            <span>
                Can't be modified <br /> due to it's {item.status} status
            </span>
        );

        setCampaignBtns({
            ...campaignBtns,

            playBtn: {
                isReadOnly: isPlayBtnReadOnly || isLowBalance,
                message: isPlayBtnReadOnly ? message : "Insufficient balance",
                isPlayBtnShown,
            },
            stopBtn: {
                isReadOnly: isStopBtnReadOnly || isLowBalance,
                message: isStopBtnReadOnly ? message : "Insufficient balance",
                isStopBtnShown,
            },
            editBtn: {
                isReadOnly: isEditBtnReadOnly,
                message: message,
                isEditBtnShown,
            },
            deleteBtn: {
                isReadOnly: isDeleteBtnReadOnly,
                message: message,
                isDeleteBtnShown,
            },
        });
    }, [campaignDetails]);

    useEffect(() => {
        setCampaignDetails((prev) => ({
            ...prev,
            isStopped: item.isPaused,
            status: item.status,
            statusNumber: item.statusNumber,
        }));
    }, []);
    useEffect(() => {}, []);

    // FETCH
    const [campaignInfoData, setCampaignInfoData] = useState({});
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const { playOrPauseCampaign, deleteCampaign, sumTransactionQuantities } = useFetch();

    // PLAY OR PAUSE CAMPAIGN
    const getPlayOrPauseCampaign = async (campaignId, toPlayCampaign) => {
        // if (!auth.currentUser) return;
        setIsPlayPauseLoading(true);

        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            campaignId,
            toPlayCampaign,
        };
        try {
            const data = await playOrPauseCampaign(() => {}, temRaw);
            const { isPaused, status = "unknown", statusNumber = null } = data.res_data;
            setCampaignDetails((prev) => ({
                ...prev,
                isStopped: isPaused,
                status: status.toLowerCase(),
                statusNumber,
            }));
            console.log(data, "=playOrPauseCampaign= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=playOrPauseCampaign= request error");
        }
        setIsPlayPauseLoading(false);
    };
    const initialFormData = {
        zonename: "",
        zonetype: "1",
        zonesize: "",
    };
    const zoneelement = document.querySelector(".openeditzone");
    const [inputValue, setInputValue] = useState(null);
    const handleEditClick = (item) => {
        localStorage.setItem("webid", item); // Save to localStorage
        zoneelement.click();
    };

    const [txnValue, setTxnValue] = useState("");
    const [txnValueIsLoading, setTxnValueIsLoading] = useState(false);
    const [activeCampaignInfoIndex, setActiveCampaignInfoIndex] = useState(null);
    const [hidewebid, sethideId] = useState(null);
    const [editwebid, seteditId] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    //websiteid=editwebid;
    //
    // const getTxnValue = async ({ smartContractAddress = "" }) => {
    //     setTxnValueIsLoading((prev) => true);
    //     const endDate = new Date(); // today
    //     const startDate = new Date(endDate);
    //     startDate.setDate(endDate.getDate() - 6); // 7 days ago

    //     const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
    //         dateFrom: formatDate(startDate),
    //         dateTill: formatDate(endDate),
    //     };
    //     const tempRaw = {
    //         // uuid: auth.currentUser.uid,
    //         // chainNetwork,
    //         // smartContractAddress,
    //         // page,
    //         // pageSize,
    //         // order: "desc",
    //         smartContractAddress: [smartContractAddress],
    //         startDate: selectionRangeFormatted.dateFrom,
    //         endDate: selectionRangeFormatted.dateTill,
    //     };
    //     try {
    //         const data = await sumTransactionQuantities(() => {}, tempRaw);
    //         // console.log(data, "=sumTransactionQuantities= request");

    //         setTxnValue(data);
    //     } catch (err) {
    //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //         console.error(err, "=sumTransactionQuantities= request error");
    //     }
    //     setTxnValueIsLoading((prev) => false);
    // };

    // useEffect(() => {
    //     getTxnValue({ smartContractAddress: item.extraData.smartContractAddress });
    // }, []);

    const [isMarked, setIsMarked] = useState(false);

    useEffect(() => {
        const marked = markedCampaigns.find((markedItem) => markedItem.id === item.id);
        setIsMarked(marked ? true : false);
    }, [markedCampaigns]);
    return (
        <>
            <tr
                className={`table-row row-${item.affiliateid === hidewebid ? "hide" : ""} 
                ${isMarked ? "active" : ""} 
                    ${isDarkModeEnabled ? "dark-mode" : ""}`}
                // onClick={() => {
                //     // setActiveCampaign(item);
                //     handleMarkedCampaigns(item, true);
                // }}
            >
                {/* <td className="table-td-hush">
                    <Button
                        variant="circle"
                        size="sm"
                        color="secondary"
                        // icon={activeRow === index ? eyeSlash : eye}
                        icon={activeCampaignInfoIndex === item.id ? arrowUp : arrowDown}
                        onClick={(e) => {
                            e.stopPropagation();
                            // setActiveRow(activeRow === index ? null : index);
                            setActiveCampaignInfoIndex(activeCampaignInfoIndex === index ? null : index);
                        }}
                    />
                </td> */}
                <td className="table-td-name text-start">
                    <span>{removeAlias(item.zonename)}</span>
                </td>
                <td className="table-td-settings text-center">
                    <div onClick={(e) => e.stopPropagation()} className="table-td-settings-wrapper">
                        <div
                            className={`edit-btn-wrapper ${false ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="edit-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.editBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.editBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Edit"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={penV2}
                                onClick={() => {
                                    handleEditClick(item.affiliateid);
                                }}
                            />
                            {campaignBtns.editBtn.isReadOnly && (
                                <Tooltip id="edit-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                        </div>
                        {/* <div
                            className={`delete-btn-wrapper ${isDeleteLoading ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="delete-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.deleteBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.deleteBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Delete"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={trashV2}
                                // disabled={isDeleteLoading}
                                // onClick={() => handleDeleteCampaign(item.id)}
                                onClick={() => {
                                    setIsDialogOpen(true);
                                    setClickedId(item.affiliateid);
                                }}
                                disabled={campaignBtns.deleteBtn.isReadOnly}
                            />
                            {campaignBtns.deleteBtn.isReadOnly && (
                                <Tooltip id="delete-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                            <ConfirmationDialog
                                isOpen={isDialogOpen}
                                onClose={handleCloseDialog}
                                onConfirm={handleConfirm}
                                message="Are you sure you want to proceed?"
                            />
                        </div> */}
                    </div>
                </td>

                <td className="table-td-impressions text-center">{addCommas(item.impressions) || 0}</td>
                <td className="table-td-ad-spend text-center">{0}</td>
                <td className="table-td-clicks text-center">{addCommas(item.clicks) || 0}</td>
                <td className="table-td-conversion text-center">{0}</td>
                <td className="table-td-ctr text-center">{formatCurrency(item.revenue, 2)}</td>
                <td className="table-td-txn-value text-center">{0}</td>
            </tr>
            {/* {activeCampaignInfoIndex === index && (
                <tr className="campaign-details-tr">
                    <td colSpan={11} className="campaign-info-td">
                        <CampaignInfo data={item} isLoading={false} />
                    </td>
                </tr>
            )} */}
        </>
    );
};
